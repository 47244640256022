/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { stringify } from "qs";
import { ConsultTopicListResultDto } from "./Dto/consult-topic-list-result.dto";
import { ConsultTopicResultDto } from "./Dto/consult-topic-result.dto";
import { HttpAPI } from "./HttpAPI";

export class ConsultAPI {
  static async updatePostById(topicId: number, postId: number, content: any) {
    return HttpAPI.patch(`/consultant/topic/${topicId}/post/${postId}`, {
      content,
    });
  }
  static async createPost(id: number, content: any) {
    return HttpAPI.post(`/consultant/topic/${id}/post`, { content });
  }
  static async getTopicById(id: number): Promise<ConsultTopicResultDto> {
    return HttpAPI.get(`/consultant/topic/${id}`);
  }

  static async pageTopic(
    page: number,
    size: number,
    filterSorters: Record<string, any>
  ): Promise<ConsultTopicListResultDto[]> {
    return HttpAPI.get(
      `/consultant/topic/${page}/${size}?${stringify(filterSorters)}`
    );
  }

  static async countTopic(filterSorters: Record<string, any>): Promise<number> {
    return HttpAPI.get(`/consultant/topic/count?${stringify(filterSorters)}`);
  }
  static async deletePostById(topic: number, post: number) {
    return HttpAPI.delete(`/consultant/topic/${topic}/post/${post}`);
  }
}
