/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { Button, Dropdown, Input, MenuProps, Modal, Table } from "antd";
import { useEffect, useState } from "react";
import { MessageInstance } from "antd/es/message/interface";
import Editor from "./Editor/Editor";
import ActionBar from "../../../../Layouts/LayoutHome/ActionBar/ActionBar";
import moment from "moment";
import { MessengerWrapper } from "../../../../Common/MessengerWrapper";
import { SecretaryAPI } from "../../../../API/secretary.api";
import { SecretaryListResultDto } from "../../../../API/Dto/secretary-list-result.dto";
import { SecretaryResultDto } from "../../../../API/Dto/secretary-result.dto";
import RecoverPassword from "./RecoverPassword/RecoverPassword";
import { SecretaryRole } from "../../../../API/Enum/secretary-role";
import { SearchOutlined } from "@ant-design/icons";

export default function ContentManager(props: { messenger: MessageInstance }) {
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(15);

  const [rows, setRows] = useState<SecretaryListResultDto[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);

  const [editingRow, setEditingRow] = useState<SecretaryResultDto>();
  const [showRecover, setShowRecover] = useState<boolean>(false);
  const [showEditor, setShowEditor] = useState<boolean>(false);

  const [searchCondition, setSearchCondition] = useState<Record<string, string | string[]>>({});

  async function search(condition: Record<string, any[]>) {
    const newCondition: Record<string, string | string[]> = {};
    if (condition.name) newCondition.name = condition.name[0];
    if (condition.mobile) newCondition.mobile = condition.mobile[0];
    if (condition.organizations) newCondition.organizations = condition.organizations[0];
    setSearchCondition({ ...newCondition });
  }

  useEffect(() => {
    refresh();
  }, [page, pageSize, searchCondition]);

  async function refresh() {
    setShowEditor(false);
    setShowRecover(false);

    MessengerWrapper(props.messenger, async () => {
      if (1 === page) {
        setRowCount(await SecretaryAPI.count(SecretaryRole.CONTENT_MANAGER, searchCondition));
      }
      setRows(await SecretaryAPI.page(SecretaryRole.CONTENT_MANAGER, page, pageSize, searchCondition));
    });
  }

  function create() {
    setEditingRow(undefined);
    setShowEditor(true);
  }

  async function edit(record: SecretaryListResultDto) {
    setEditingRow(await SecretaryAPI.getById(record.id));
    setShowEditor(true);
  }

  async function recover(record: SecretaryListResultDto) {
    setEditingRow(await SecretaryAPI.getById(record.id));
    setShowRecover(true);
  }

  function remove(record: SecretaryListResultDto) {
    Modal.confirm({
      title: "确认删除?",
      content: `此操作将不可恢复的删除 ${record.name}`,
      onOk: () => {
        MessengerWrapper(props.messenger, async () => {
          await SecretaryAPI.removeById(record.id);
          refresh();
        });
      },
    });
  }

  return (
    <div>
      <ActionBar>
        <Button className="add" type="ghost" onClick={() => create()}>
          {"添加内容管理员"}
        </Button>
      </ActionBar>
      <div className="table-title">所有内容管理员</div>
      <Table
        rowKey={"id"}
        dataSource={rows}
        onChange={(p, f: Record<string, any>) => p.current === page && search(f)}
        columns={[
          {
            key: "name",
            title: "名称",
            dataIndex: "name",
            width: 180,
            filterDropdown: ({ selectedKeys, setSelectedKeys, confirm, clearFilters, close }) => (
              <div className="table-search">
                <Input
                  placeholder="搜索名称"
                  value={selectedKeys[0]}
                  onChange={(e) => setSelectedKeys((e.target.value ? [e.target.value] : []) as any)}
                />
                <Button
                  type="primary"
                  className="search-button"
                  onClick={() => confirm()}
                >
                  搜索
                </Button>
                <Button
                  type="default"
                  className="reset-button"
                  onClick={() => {
                    clearFilters!();
                  }}
                >
                  重置
                </Button>
                <Button
                  type="link"
                  className="close-button"
                  onClick={() => {
                    close();
                  }}
                >
                  关闭
                </Button>
              </div>
            ),
            filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />,
          },
          {
            key: "mobile",
            title: "手机号",
            dataIndex: "mobile",
            width: 180,
            filterDropdown: ({ selectedKeys, setSelectedKeys, confirm, clearFilters, close }) => (
              <div className="table-search">
                <Input
                  placeholder="搜索手机号"
                  value={selectedKeys[0]}
                  onChange={(e) => setSelectedKeys((e.target.value ? [e.target.value] : []) as any)}
                />
                <Button
                  type="primary"
                  className="search-button"
                  onClick={() => confirm()}
                >
                  搜索
                </Button>
                <Button
                  type="default"
                  className="reset-button"
                  onClick={() => {
                    clearFilters!();
                  }}
                >
                  重置
                </Button>
                <Button
                  type="link"
                  className="close-button"
                  onClick={() => {
                    close();
                  }}
                >
                  关闭
                </Button>
              </div>
            ),
            filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />,
          },
          {
            key: "createdAt",
            title: "创建于",
            dataIndex: "createdAt",
            render: (value) => moment(value).format("YYYY-MM-DD HH:mm:ss"),
            width: 200,
          },
          {
            key: "action",
            title: "操作",
            dataIndex: "id",
            width: 60,
            render: (value, record, index) => {
              const items: MenuProps['items'] = [
                {
                  key: 'recover',
                  label: "重置密码",
                  onClick: () => recover(record),
                },
                {
                  key: 'edit',
                  label: "编辑",
                  onClick: () => edit(record),
                },
                {
                  key: 'delete',
                  label: "删除",
                  onClick: () => remove(record),
                },
              ];

              return <Dropdown menu={{ items }}>
                <a onClick={(e) => e.preventDefault()} className="table-action-more"></a>
              </Dropdown>;

            },
          },
        ]}
        pagination={{
          pageSize,
          total: rowCount,
          pageSizeOptions: [10, 15, 20, 50, 100],
          showSizeChanger: true,
          position: ["bottomRight"],
          onChange: (page, pageSize) => {
            setPage(page);
            setPageSize(pageSize);
          },
        }}
      ></Table>
      {showEditor && (
        <Editor
          messenger={props.messenger}
          editing={editingRow}
          onDone={() => refresh()}
        />
      )}
      {showRecover && (
        <RecoverPassword
          messenger={props.messenger}
          editing={editingRow as SecretaryResultDto}
          onDone={() => refresh()}
        />
      )}
    </div>
  );
}
