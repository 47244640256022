/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

export class HttpAPI {
  public static readonly baseUrl = "/api/backend";

  public static async head(...params: Parameters<(typeof axios)["head"]>) {
    try {
      params[0] = [
        this.baseUrl.replace(/\/$/, ""),
        params[0].replace(/^\//, ""),
      ].join("/");
      params[1] = injectToken(params[1] || {});
      return handleResponse(await axios.head(...params));
    } catch (e: any) {
      const axiosError = e as AxiosError;
      if (401 === axiosError.response?.status) {
        window.location.href = "/login";
      }
      throw handleErrorResponse(axiosError.response);
    }
  }

  public static async get(...params: Parameters<(typeof axios)["get"]>) {
    try {
      params[0] = [
        this.baseUrl.replace(/\/$/, ""),
        params[0].replace(/^\//, ""),
      ].join("/");
      params[1] = injectToken(params[1] || {});
      return handleResponse(await axios.get(...params));
    } catch (e: any) {
      const axiosError = e as AxiosError;
      if (401 === axiosError.response?.status) {
        window.location.href = "/login";
      }
      throw handleErrorResponse(axiosError.response);
    }
  }

  public static async post(...params: Parameters<(typeof axios)["post"]>) {
    try {
      params[0] = [
        this.baseUrl.replace(/\/$/, ""),
        params[0].replace(/^\//, ""),
      ].join("/");
      params[2] = injectToken(params[2] || {});
      return handleResponse(await axios.post(...params));
    } catch (e: any) {
      const axiosError = e as AxiosError;
      if (401 === axiosError.response?.status) {
        window.location.href = "/login";
      }
      throw handleErrorResponse(axiosError.response);
    }
  }

  public static async patch(...params: Parameters<(typeof axios)["patch"]>) {
    try {
      params[0] = [
        this.baseUrl.replace(/\/$/, ""),
        params[0].replace(/^\//, ""),
      ].join("/");
      params[2] = injectToken(params[2] || {});
      return handleResponse(await axios.patch(...params));
    } catch (e: any) {
      const axiosError = e as AxiosError;
      if (401 === axiosError.response?.status) {
        window.location.href = "/login";
      }
      throw handleErrorResponse(axiosError.response);
    }
  }

  public static async delete(...params: Parameters<(typeof axios)["delete"]>) {
    try {
      params[0] = [
        this.baseUrl.replace(/\/$/, ""),
        params[0].replace(/^\//, ""),
      ].join("/");
      params[1] = injectToken(params[1] || {});
      return handleResponse(await axios.delete(...params));
    } catch (e: any) {
      const axiosError = e as AxiosError;
      if (401 === axiosError.response?.status) {
        window.location.href = "/login";
      }
      throw handleErrorResponse(axiosError.response);
    }
  }

  static setOrganization(id: number) {
    localStorage.setItem("organization", id.toString());
    this._handlers.forEach((v) => v());
  }

  static getOrganization(): number {
    return parseInt(localStorage.getItem("organization") || "0");
  }

  static clearOrganization() {
    localStorage.removeItem("organization");
    this._handlers.forEach((v) => v());
  }

  private static _handlers: (() => void)[] = [];
  static onOrganizationChange(handler: () => void) {
    this._handlers.push(handler);
  }
  static offOrganizationChange(handler: () => void) {
    const handlerIndex = this._handlers.indexOf(handler);
    if (-1 === handlerIndex) return;
    this._handlers.splice(handlerIndex, 1);
  }
}

function handleResponse(response: AxiosResponse<any, any>) {
  const accessToken = response.headers["x-access-token"];
  if (!accessToken) return response.data;
  localStorage.setItem("secretary-access-token", accessToken);
  return response.data;
}
function handleErrorResponse(response: AxiosResponse<any, any> | undefined) {
  const accessToken = response?.headers["x-access-token"];
  if (!accessToken) return { data: response?.data, status: response?.status };
  localStorage.setItem("secretary-access-token", accessToken);
  return { data: response?.data, status: response?.status };
}
function injectToken<T>(config: AxiosRequestConfig<T>): AxiosRequestConfig<T> {
  const accessToken = localStorage.getItem("secretary-access-token");
  if (!accessToken) return config;
  config.headers = config.headers || {};
  config.headers.Authorization = `Bearer ${accessToken}`;
  if (localStorage.getItem("organization")) {
    config.headers["X-Organization"] = localStorage.getItem("organization");
  }
  return config;
}
