/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { HttpAPI } from "./HttpAPI";
import { NotificationCreateDto } from "./notification-create.dto";

export class NotificationAPI {
  static async pageGlobalNotification(page: number, size: number) {
    return HttpAPI.get(`/notification/global/${page}/${size}`);
  }
  static async pageOrganizationNotification(page: number, size: number) {
    return HttpAPI.get(`/notification/organization/${page}/${size}`);
  }
  static async pageUserNotification(page: number, size: number) {
    return HttpAPI.get(`/notification/user/${page}/${size}`);
  }

  static async countGlobalNotification() {
    return HttpAPI.get("/notification/global/count");
  }
  static async countOrganizationNotification() {
    return HttpAPI.get("/notification/organization/count");
  }
  static async countUserNotification() {
    return HttpAPI.get("/notification/user/count");
  }

  static async createUserNotification(formData: NotificationCreateDto) {
    return HttpAPI.post(`/notification/user`, formData);
  }

  static async createOrganizationNotification(
    organizationId: number,
    formData: NotificationCreateDto
  ) {
    return HttpAPI.post(
      `/notification/organization/${organizationId}`,
      formData
    );
  }

  static async createGlobalNotification(formData: NotificationCreateDto) {
    return HttpAPI.post(`/notification/global`, formData);
  }

  static async removeUserNotificationById(id: number) {
    return HttpAPI.delete(`/notification/user/${id}`);
  }

  static async removeOrganizationNotificationById(id: number) {
    return HttpAPI.delete(`/notification/organization/${id}`);
  }

  static async removeGlobalNotificationById(id: number) {
    return HttpAPI.delete(`/notification/global/${id}`);
  }
}
