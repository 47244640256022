/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import "./LayoutLogin.scss";

export default function LayoutLogin(props: { children: React.ReactNode }) {
  return (
    <div className="layout-login">
      <div className="left">
        <div className="center logo"></div>
      </div>
      <div className="right">
        <div className="center">
          <div className="form">
            <div className="shadow"></div>
            <div className="content">{props.children}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
