/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { Button, DatePicker, Input, Table } from "antd";
import { useEffect, useState } from "react";
import { MessageInstance } from "antd/es/message/interface";
// import Editor from "./Editor/Editor";
import ActionBar from "../../../../Layouts/LayoutHome/ActionBar/ActionBar";
import moment from "moment";
import { MessengerWrapper } from "../../../../Common/MessengerWrapper";
import { UserAPI } from "../../../../API/User.api";
import { HttpAPI } from "../../../../API/HttpAPI";
import { UserResultDto } from "../../../../API/Dto/user-result.dto";
import { CalendarOutlined, SearchOutlined } from "@ant-design/icons";
// import Importer from "./Importer/Importer";
// import { AuthAPI } from "../../../../API/Auth.api";

export default function User(props: { messenger: MessageInstance }) {
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(15);

  const [rows, setRows] = useState<UserResultDto[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);

  // const [editingRow, setEditingRow] = useState<UserResultDto>();
  // const [showEditor, setShowEditor] = useState<boolean>(false);
  // const [showImporter, setShowImporter] = useState<boolean>(false);

  // const user = AuthAPI.getLocalUser();

  const [searchCondition, setSearchCondition] = useState<Record<string, string | string[]>>({});

  useEffect(() => {
    refresh();
    HttpAPI.onOrganizationChange(refresh);
    return () => {
      HttpAPI.offOrganizationChange(refresh);
    };
  }, [page, pageSize, searchCondition]);

  async function search(condition: Record<string, any[]>) {
    const newCondition: Record<string, string | string[]> = {};
    if (condition.name) newCondition.name = condition.name[0];
    if (condition.mobile) newCondition.mobile = condition.mobile[0];
    if (condition.gender) newCondition.gender = condition.gender[0];
    if (condition.organization) newCondition.organization = condition.organization[0];
    if (condition.createdAt) newCondition.createdAt = condition.createdAt;
    setSearchCondition({ ...newCondition });
  }

  async function refresh() {
    // setShowEditor(false);
    // setShowImporter(false);

    MessengerWrapper(props.messenger, async () => {
      if (1 === page) {
        setRowCount(await UserAPI.count(searchCondition));
      }
      setRows(await UserAPI.page(page, pageSize, searchCondition));
    });
  }


  return (
    <div>
      <ActionBar></ActionBar>
      <Table
        rowKey={"id"}
        dataSource={rows}
        onChange={(p, f: Record<string, any>) => p.current === page && search(f)}
        columns={[
          {
            key: "name",
            title: "姓名",
            dataIndex: "name",
            width: 180,
            filterDropdown: ({ selectedKeys, setSelectedKeys, confirm, clearFilters, close }) => (
              <div className="table-search">
                <Input
                  placeholder="搜索名称"
                  value={selectedKeys[0]}
                  onChange={(e) => setSelectedKeys((e.target.value ? [e.target.value] : []) as any)}
                />
                <Button
                  type="primary"
                  className="search-button"
                  onClick={() => confirm()}
                >
                  搜索
                </Button>
                <Button
                  type="default"
                  className="reset-button"
                  onClick={() => {
                    clearFilters!();
                  }}
                >
                  重置
                </Button>
                <Button
                  type="link"
                  className="close-button"
                  onClick={() => {
                    close();
                  }}
                >
                  关闭
                </Button>
              </div>
            ),
            filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />,
          },
          {
            key: "gender",
            title: "性别",
            dataIndex: "gender",
            width: 180,
            render: (value) => (({ 'MALE': '男', 'FEMALE': '女' } as any)[value]),
            filters: [
              {
                text: '男',
                value: 'MALE',
              },
              {
                text: '女',
                value: 'FEMALE',
              },
            ],
          },
          {
            key: "mobile",
            title: "手机号",
            dataIndex: "mobile",
            width: 180,
            filterDropdown: ({ selectedKeys, setSelectedKeys, confirm, clearFilters, close }) => (
              <div className="table-search">
                <Input
                  placeholder="搜索名称"
                  value={selectedKeys[0]}
                  onChange={(e) => setSelectedKeys((e.target.value ? [e.target.value] : []) as any)}
                />
                <Button
                  type="primary"
                  className="search-button"
                  onClick={() => confirm()}
                >
                  搜索
                </Button>
                <Button
                  type="default"
                  className="reset-button"
                  onClick={() => {
                    clearFilters!();
                  }}
                >
                  重置
                </Button>
                <Button
                  type="link"
                  className="close-button"
                  onClick={() => {
                    close();
                  }}
                >
                  关闭
                </Button>
              </div>
            ),
            filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />,
          },
          {
            key: "createdAt",
            title: "创建日期",
            dataIndex: "createdAt",
            width: 240,
            render: (value) => moment(value).format("YYYY/MM/DD HH:mm:ss"),
            filterDropdown: ({ selectedKeys, setSelectedKeys, confirm, clearFilters, close }) => (
              <div className="table-search">
                <DatePicker.RangePicker
                  onChange={(e) => setSelectedKeys([e?.[0]?.format('YYYY-MM-DD') || '', e?.[1]?.format('YYYY-MM-DD') || ''])}

                />
                <Button
                  type="primary"
                  className="search-button"
                  onClick={() => confirm()}
                >
                  搜索
                </Button>
                <Button
                  type="default"
                  className="reset-button"
                  onClick={() => {
                    clearFilters!();
                  }}
                >
                  重置
                </Button>
                <Button
                  type="link"
                  className="close-button"
                  onClick={() => {
                    close();
                  }}
                >
                  关闭
                </Button>
              </div>
            ),
            filterIcon: (filtered: boolean) => <CalendarOutlined style={{ color: filtered ? '#1677ff' : undefined }} />,
          },
          {
            key: "organization",
            title: "所在公司",
            dataIndex: ["organization", "name"],
            filterDropdown: ({ selectedKeys, setSelectedKeys, confirm, clearFilters, close }) => (
              <div className="table-search">
                <Input
                  placeholder="搜索名称"
                  value={selectedKeys[0]}
                  onChange={(e) => setSelectedKeys((e.target.value ? [e.target.value] : []) as any)}
                />
                <Button
                  type="primary"
                  className="search-button"
                  onClick={() => confirm()}
                >
                  搜索
                </Button>
                <Button
                  type="default"
                  className="reset-button"
                  onClick={() => {
                    clearFilters!();
                  }}
                >
                  重置
                </Button>
                <Button
                  type="link"
                  className="close-button"
                  onClick={() => {
                    close();
                  }}
                >
                  关闭
                </Button>
              </div>
            ),
            filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />,
          },
          // {
          //   key: "action",
          //   title: "操作",
          //   dataIndex: "id",
          //   render: (value, record, index) => (
          //     <>
          //       {user?.role === SecretaryRole.BUTLER && (
          //         <>
          //           <Button type="link" onClick={() => edit(record)}>
          //             编辑
          //           </Button>
          //           <Button type="link" onClick={() => remove(record)}>
          //             删除
          //           </Button>
          //         </>
          //       )}
          //     </>
          //   ),
          // },
        ]}
        pagination={{
          pageSize,
          total: rowCount,
          pageSizeOptions: [10, 15, 20, 50, 100],
          showSizeChanger: true,
          position: ["bottomRight"],
          onChange: (page, pageSize) => {
            setPage(page);
            setPageSize(pageSize);
          },
        }}
      ></Table>
      {/* {
        showEditor && (
          <Editor
            messenger={props.messenger}
            editing={editingRow}
            onDone={() => refresh()}
          />
        )
      }
      {
        showImporter && (
          <Importer
            messenger={props.messenger}
            onDone={() => refresh()}
          />
        )
      } */}
    </div >
  );
}
