/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { OrganizationListResultDto } from "./Dto/organization-list-result.dto";
import { OrganizationResultDto } from "./Dto/organization-result.dto";
import { OrganizationCreateDto } from "./Dto/organization-create.dto";
import { OrganizationRenameDto } from "./Dto/organization-rename.dto";
import { HttpAPI } from "./HttpAPI";
import { stringify } from "qs";

export class OrganizationAPI {
  static create(data: OrganizationCreateDto): Promise<OrganizationResultDto> {
    return HttpAPI.post("/organization", data);
  }
  static renameById(
    id: number,
    data: OrganizationRenameDto
  ): Promise<OrganizationResultDto> {
    return HttpAPI.patch(`/organization/${id}`, data);
  }
  static remove(id: number): Promise<OrganizationResultDto> {
    return HttpAPI.delete(`/organization/${id}`);
  }

  static count(condition: Record<string, string | string[]>): Promise<number> {
    return HttpAPI.get(`/organization/count?${stringify(condition)}`);
  }
  static page(
    page: number,
    pageSize: number,
    condition: Record<string, string | string[]>
  ): Promise<OrganizationListResultDto[]> {
    return HttpAPI.get(
      `/organization/${page}/${pageSize}?${stringify(condition)}`
    );
  }

  static get(): Promise<OrganizationListResultDto[]> {
    return HttpAPI.get("/organization");
  }

  static getRelated(): Promise<OrganizationListResultDto[]> {
    return HttpAPI.get("/organization/related");
  }
}
