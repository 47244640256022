/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { Button, Form, Input, Switch } from "antd";
import TextArea from "antd/es/input/TextArea";

import { MessageInstance } from "antd/es/message/interface";

import "./Editor.scss";
import { useEffect, useState } from "react";
import { RcFile } from "antd/es/upload";
import { CategoryTreeResultDto } from "../../../../../API/Dto/category-tree-result.dto";
import { OSSApi } from "../../../../../API/oss.api";
import ModalEditor from "../../../../../Layouts/ModalEditor/ModalEditor";
import { CategoryCreateDto } from "../../../../../API/Dto/category-create.dto";
import Upload from "antd/es/upload/Upload";
import { PlusOutlined } from "@ant-design/icons";
import { CategoryAPI } from "../../../../../API/category.api";

export default function Editor(props: {
  messenger: MessageInstance;
  parent?: CategoryTreeResultDto;
  editing?: CategoryTreeResultDto;
  onDone: () => void;
}) {
  const [coverFile, setCoverFile] = useState<RcFile | null>(null);
  const [isHidden, setIsHidden] = useState<boolean>(false);

  useEffect(() => {
    setIsHidden(props.editing?.hidden || false);
  }, [props.editing]);

  function done() {
    setCoverFile(null);
    props.onDone();
  }

  async function preventUpload(setFunc: (f: RcFile) => void, file: RcFile) {
    setFunc(file);
    return false;
  }

  return (
    <ModalEditor
      messenger={props.messenger}
      editing={props.editing}
      name="分类"
      title={
        props.editing
          ? `编辑${props.editing.display}`
          : props.parent
            ? `在 ${props.parent.display} 下创建新分类`
            : "创建新顶级分类"
      }
      onDone={done}
      onSubmit={async (d) => {
        const formData: CategoryCreateDto = {
          display: d.display,
          hidden: isHidden || false,
          description: d.description || "",
          coverImageUrl: coverFile
            ? await OSSApi.upload(coverFile)
            : props.editing?.coverImageUrl || "",
        };

        if (props.editing) {
          await CategoryAPI.update(props.editing.id, formData);
        } else if (props.parent) {
          await CategoryAPI.createUnderParent(props.parent.id, formData);
        } else {
          await CategoryAPI.create(formData);
        }
      }}
    >
      <div className="form-row">
        <div className="form-row-expanded">
          <Form.Item
            label="名称"
            name="display"
            rules={[{ required: true, message: "请输入名称" }]}
          >
            <Input />
          </Form.Item>
        </div>
        <Form.Item label="隐藏" name="hidden">
          <Switch
            checked={isHidden || false}
            onChange={(d) => setIsHidden(d)}
          />
        </Form.Item>
      </div>

      <Form.Item label="描述" name="description">
        <TextArea rows={4} />
      </Form.Item>
      <Form.Item
        label="封面图"
        name="coverFile"
        rules={[{ required: false, message: "请输入描述" }]}
      >
        <Upload
          accept=".jpg, .png, .jpeg"
          listType="picture-card"
          className="album-uploader"
          showUploadList={false}
          beforeUpload={(f) => preventUpload(setCoverFile, f)}
        >
          <div className="previewer">
            {coverFile || props.editing ? (
              <img
                src={
                  coverFile
                    ? window.URL.createObjectURL(new Blob([coverFile]))
                    : props.editing?.coverImageUrl
                }
              />
            ) : (
              <div>
                <div style={{ marginTop: 8 }}>
                  <PlusOutlined /> 请上传封面图
                </div>
              </div>
            )}
          </div>
        </Upload>
      </Form.Item>
      <Form.Item className="save-button">
        <Button type="primary" htmlType="submit">
          {props.editing ? '确认修改' : '确认创建'}
        </Button>
      </Form.Item>
    </ModalEditor>
  );
}
