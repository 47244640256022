/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { Table } from "antd";
import { useEffect, useState } from "react";
import { MessageInstance } from "antd/es/message/interface";
import ActionBar from "../../../../Layouts/LayoutHome/ActionBar/ActionBar";
import { MessengerWrapper } from "../../../../Common/MessengerWrapper";
import { DateComplainAPI } from "../../../../API/date-complain.api";
import { DateComplainResultDto } from "../../../../API/Dto/date-complain-result.dto";
import moment from "moment";

export default function DateComplain(props: { messenger: MessageInstance }) {
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(15);

  const [rows, setRows] = useState<DateComplainResultDto[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);

  useEffect(() => {
    refresh();
  }, [page, pageSize]);

  async function refresh() {

    MessengerWrapper(props.messenger, async () => {
      if (1 === page) {
        setRowCount(await DateComplainAPI.count());
      }
      setRows(await DateComplainAPI.page(page, pageSize));
    });
  }

  return (
    <div>
      <ActionBar>
      </ActionBar>
      <div className="table-title">所有举报</div>
      <Table
        rowKey={"id"}
        dataSource={rows}
        columns={[
          {
            key: "complainer",
            title: "举报人",
            dataIndex: "complainer",
            render: (v, r) => `${v.name}(${v.mobile.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2')})`,
            width: 200,
          },
          {
            key: "display",
            title: "被举报人",
            dataIndex: "complaining",
            render: (v, r) => `${v.name}(${v.mobile.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2')})`,
            width: 200,
          },
          {
            key: "reference",
            title: "举报内容",
            dataIndex: "reference",
          },
          {
            key: "reasons",
            title: "原因",
            dataIndex: "reasons",
            render: (v, r) => v.map((r: any) => <div>{r}</div>),
          },
          {
            key: "complainedAt",
            title: "时间",
            dataIndex: "complainedAt",
            render: (v) => moment(v).format("YYYY-MM-DD HH:mm:ss"),
            width: 200,
          },
        ]}
        pagination={{
          pageSize,
          total: rowCount,
          pageSizeOptions: [10, 15, 20, 50, 100],
          showSizeChanger: true,
          position: ["bottomRight"],
          onChange: (page, pageSize) => {
            setPage(page);
            setPageSize(pageSize);
          },
        }}
      ></Table>
    </div>
  );
}
