/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { CategoryCreateDto } from "./Dto/category-create.dto";
import { CategoryTreeResultDto } from "./Dto/category-tree-result.dto";
import { CategoryUpdateDto } from "./Dto/category-update.dto";
import { HttpAPI } from "./HttpAPI";

export class CategoryAPI {
  public static async getTree(): Promise<CategoryTreeResultDto[]> {
    return HttpAPI.get("/category/tree");
  }

  public static async create(data: CategoryCreateDto) {
    return HttpAPI.post("/category", data);
  }

  public static async createUnderParent(
    parentId: number,
    data: CategoryCreateDto
  ) {
    return HttpAPI.post(`/category/${parentId}`, data);
  }

  public static async update(id: number, data: CategoryUpdateDto) {
    return HttpAPI.patch(`/category/${id}`, data);
  }

  public static async remove(id: number) {
    return HttpAPI.delete(`/category/${id}`);
  }
}
