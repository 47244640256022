/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { Modal } from "antd";
import "./LayoutHome.scss";
import { MessageInstance } from "antd/es/message/interface";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthAPI } from "../../API/Auth.api";
import { SecretaryRole } from "../../API/Enum/secretary-role";

// type MenuItem = Required<MenuProps>["items"][number];

export default function LayoutHome(props: {
  messenger: MessageInstance;
  children?: React.ReactNode;
}) {
  const { pathname } = useLocation();
  const [, activeMenu, activeSubMenu] = pathname.split('/').filter(v => v);

  const navigate = useNavigate();
  const user = AuthAPI.getLocalUser();

  const logout = () => {
    Modal.confirm({
      'title': '确认登出', 'content': '是否确认登出', onOk: async () => {
        await AuthAPI.logout();
        navigate('/login');
      },
    })
  }


  const title = ({
    'metadata/date-complain': '举报',
    'customer/organization': '公司管理',
    'customer/butler': '大使管理',
    'customer/user': '用户管理',
    'news/category': '新闻分类',
    'news/video': '大讲堂管理',
    'news/tag': '大讲堂标签管理',
    'news/article': '新闻管理',
    'consultant/consultant': '咨询师管理',
    'consult/topic': `你好, ${user?.name}`,
    'profile/profile': `你好, ${user?.name}`,
  } as any)[`${activeMenu}/${activeSubMenu}`] || ''
    ;

  return (
    <main>
      <nav>
        <div className="logo"></div>
        <div className="menu" hidden={SecretaryRole.CUSTOMER_SERVICE !== user?.role}>
          <div className="menu-item customer" data-active={'customer' === activeMenu} onClick={() => navigate('/home/customer')}>
            <div className="icon"><div className="image"></div></div>
            <div className="text">人员与组织</div>
          </div>
          <div className="menu-item news" data-active={'news' === activeMenu} onClick={() => navigate('/home/news')}>
            <div className="icon"><div className="image"></div></div>
            <div className="text">新闻与大讲堂</div>
          </div>
          <div className="menu-item metadata" data-active={'metadata' === activeMenu} onClick={() => navigate('/home/metadata')}>
            <div className="icon"><div className="image"></div></div>
            <div className="text">数据管理</div>
          </div>
          <div className="menu-item version" data-active={'version' === activeMenu} onClick={() => navigate('/home/version')}>
            <div className="icon"><div className="image"></div></div>
            <div className="text">版本管理</div>
          </div>
          <div className="menu-item notification" data-active={'notification' === activeMenu} onClick={() => navigate('/home/notification')}>
            <div className="icon"><div className="image"></div></div>
            <div className="text">通知管理</div>
          </div>
          <div className="menu-item profile" data-active={'profile' === activeMenu} onClick={() => navigate('/home/profile')}>
            <div className="icon"><div className="image"></div></div>
            <div className="text">信息设置</div>
          </div>
        </div>
        <div className="menu" hidden={SecretaryRole.CONTENT_MANAGER !== user?.role}>
          <div className="menu-item news" data-active={'news' === activeMenu} onClick={() => navigate('/home/news')}>
            <div className="icon"><div className="image"></div></div>
            <div className="text">新闻与大讲堂</div>
          </div>
          <div className="menu-item profile" data-active={'profile' === activeMenu} onClick={() => navigate('/home/profile')}>
            <div className="icon"><div className="image"></div></div>
            <div className="text">信息设置</div>
          </div>
        </div>
        <div className="menu" hidden={SecretaryRole.CONSULTANT !== user?.role}>
          <div className="menu-item consult" data-active={'consult' === activeMenu} onClick={() => navigate('/home/consult')}>
            <div className="icon"><div className="image"></div></div>
            <div className="text">提问管理</div>
          </div>
          <div className="menu-item profile" data-active={'profile' === activeMenu} onClick={() => navigate('/home/profile')}>
            <div className="icon"><div className="image"></div></div>
            <div className="text">信息设置</div>
          </div>
        </div>
        <div className="menu" hidden={SecretaryRole.BUTLER !== user?.role}>
          <div className="menu-item user" data-active={'user' === activeMenu} onClick={() => navigate('/home/user')}>
            <div className="icon"><div className="image"></div></div>
            <div className="text">用户管理</div>
          </div>
          <div className="menu-item profile" data-active={'profile' === activeMenu} onClick={() => navigate('/home/profile')}>
            <div className="icon"><div className="image"></div></div>
            <div className="text">信息设置</div>
          </div>
        </div>
        <div className="logout">
          <div className="menu-item logout" onClick={logout}>
            <div className="icon"><div className="image"></div></div>
            <div className="text">退出登录</div>
          </div>
        </div>
      </nav>
      <aside>
        <div className="menu-group" hidden={'customer' !== activeMenu}>
          <div className="menu-item customer-service" data-active={'customer-service' === activeSubMenu} onClick={() => navigate('/home/customer/customer-service')}>
            <div className="icon"><div className="image"></div></div>
            <div className="text">客服管理</div>
          </div>
          <div className="menu-item content-manager" data-active={'content-manager' === activeSubMenu} onClick={() => navigate('/home/customer/content-manager')}>
            <div className="icon"><div className="image"></div></div>
            <div className="text">内容管理员</div>
          </div>
          <div className="menu-item organization" data-active={'organization' === activeSubMenu} onClick={() => navigate('/home/customer/organization')}>
            <div className="icon"><div className="image"></div></div>
            <div className="text">公司管理</div>
          </div>
          <div className="menu-item butler" data-active={'butler' === activeSubMenu} onClick={() => navigate('/home/customer/butler')}>
            <div className="icon"><div className="image"></div></div>
            <div className="text">大使管理</div>
          </div>
          <div className="menu-item user" data-active={'user' === activeSubMenu} onClick={() => navigate('/home/customer/user')}>
            <div className="icon"><div className="image"></div></div>
            <div className="text">用户筛选</div>
          </div>
          <div className="menu-item consultant" data-active={'consultant' === activeSubMenu} onClick={() => navigate('/home/customer/consultant')}>
            <div className="icon"><div className="image"></div></div>
            <div className="text">咨询师</div>
          </div>
        </div>
        <div className="menu-group" hidden={'news' !== activeMenu}>
          <div className="menu-item category" data-active={'category' === activeSubMenu} onClick={() => navigate('/home/news/category')}>
            <div className="icon"><div className="image"></div></div>
            <div className="text">新闻分类</div>
          </div>
          <div className="menu-item article" data-active={'article' === activeSubMenu} onClick={() => navigate('/home/news/article')}>
            <div className="icon"><div className="image"></div></div>
            <div className="text">新闻</div>
          </div>
          <div className="menu-item tag" data-active={'tag' === activeSubMenu} onClick={() => navigate('/home/news/tag')}>
            <div className="icon"><div className="image"></div></div>
            <div className="text">大讲堂标签</div>
          </div>
          <div className="menu-item video" data-active={'video' === activeSubMenu} onClick={() => navigate('/home/news/video')}>
            <div className="icon"><div className="image"></div></div>
            <div className="text">大讲堂视频</div>
          </div>
        </div>
        <div className="menu-group" hidden={'metadata' !== activeMenu}>
          <div className="menu-item article" data-active={'date-tag-family' === activeSubMenu} onClick={() => navigate('/home/metadata/date-tag-family')}>
            <div className="icon"><div className="image"></div></div>
            <div className="text">标签族</div>
          </div>
          <div className="menu-item tag" data-active={'date-tag-option' === activeSubMenu} onClick={() => navigate('/home/metadata/date-tag-option')}>
            <div className="icon"><div className="image"></div></div>
            <div className="text">标签项</div>
          </div>
          <div className="menu-item complain" data-active={'date-complain' === activeSubMenu} onClick={() => navigate('/home/metadata/date-complain')}>
            <div className="icon"><div className="image"></div></div>
            <div className="text">举报</div>
          </div>
        </div>
      </aside>
      <article>
        {title && <div className="title">{title}</div>}
        {props.children}
      </article>
    </main>
  );
}
