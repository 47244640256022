/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { stringify } from "qs";
import { VideoCreateDto } from "./Dto/video-create.dto";
import { VideoListResultDto } from "./Dto/video-list-result.dto";
import { VideoResultDto } from "./Dto/video-result.dto";
import { VideoUpdateDto } from "./Dto/video-update.dto";
import { HttpAPI } from "./HttpAPI";

export class VideoAPI {
  static getById(id: number): Promise<VideoResultDto> {
    return HttpAPI.get(`/video/id/${id}`);
  }

  static count(condition: Record<string, string | string[]>): Promise<number> {
    return HttpAPI.get(`/video/count?${stringify(condition)}`);
  }

  static page(
    page: number,
    size: number,
    condition: Record<string, string | string[]>
  ): Promise<VideoListResultDto[]> {
    return HttpAPI.get(`/video/${page}/${size}?${stringify(condition)}`);
  }

  static pageByTag(
    tagId: number,
    page: number,
    size: number
  ): Promise<VideoListResultDto[]> {
    return HttpAPI.get(`/video/tag/${tagId}/${page}/${size}`);
  }

  static countByTag(tagId: number): Promise<number> {
    return HttpAPI.get(`/video/tag/${tagId}/count`);
  }

  static publish(id: number) {
    return HttpAPI.post(`/video/${id}/publish`);
  }

  static unpublish(id: number) {
    return HttpAPI.delete(`/video/${id}/publish`);
  }

  static create(data: VideoCreateDto) {
    return HttpAPI.post(`/video`, data);
  }

  static updateById(id: number, data: VideoUpdateDto) {
    return HttpAPI.patch(`/video/${id}`, data);
  }

  static removeById(id: number) {
    return HttpAPI.delete(`/video/${id}`);
  }
}
