/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { Button, Input } from "antd";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MessageInstance } from "antd/es/message/interface";
import LayoutLogin from "../../../Layouts/LayoutLogin/LayoutLogin";
import { AuthAPI } from "../../../API/Auth.api";

export default function RecoverPassword(props: { messenger: MessageInstance }) {
  const navigate = useNavigate();

  const [mobile, setMobile] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => setIsDisabled(!mobile), [mobile]);

  async function performCaptcha() {
    try {
      await AuthAPI.requestCaptchaByRecover(mobile);
      navigate(`/login/recover-password/captcha?mobile=${mobile}`)
    } catch (e: any) {
      props.messenger.open({
        className: 'global-error-toast',
        content: e?.data?.message,
        duration: 3,
      });
    }
  }

  return <LayoutLogin>
    <div className="legend large">输入账号信息</div>
    <div className="input-group">
      <div className="label">请输入需要找回密码的手机号码</div>
      <Input type="text" placeholder='输入手机号' value={mobile}
        onInput={(v) => setMobile((v.target as HTMLInputElement).value)}
      />
    </div>
    <div className="button-link-group">
      <div className="button-group"><Button className={"submit-button"} onClick={performCaptcha} disabled={isDisabled}>发送验证码</Button></div>
      <div className="link-group"><Link className="link" to={'/login'}>取消</Link></div>
    </div>
  </LayoutLogin>
}