/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { DateTagFamilyCreateDto } from "./Dto/date-tag-family-create.dto";
import { DateTagFamilyUpdateDto } from "./Dto/date-tag-family-update.dto";
import { HttpAPI } from "./HttpAPI";

export class DateTagFamilyAPI {
  static get() {
    return HttpAPI.get(`/date/tag/family`);
  }

  static page(page: number, size: number) {
    return HttpAPI.get(`/date/tag/family/${page}/${size}`);
  }
  static count() {
    return HttpAPI.get("/date/tag/family/count");
  }

  static removeById(id: number) {
    return HttpAPI.delete(`/date/tag/family/${id}`);
  }

  static create(data: DateTagFamilyCreateDto) {
    return HttpAPI.post("/date/tag/family", data);
  }

  static updateById(id: number, data: DateTagFamilyUpdateDto) {
    return HttpAPI.patch(`/date/tag/family/${id}`, data);
  }
}
