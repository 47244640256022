/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { stringify } from "qs";
import { VideoTagCreateDto } from "./Dto/video-tag-create.dto";
import { VideoTagListResultDto } from "./Dto/video-tag-list-result.dto";
import { VideoTagUpdateDto } from "./Dto/video-tag-update.dto";
import { HttpAPI } from "./HttpAPI";

export class VideoTagAPI {
  static get(): Promise<VideoTagListResultDto[]> {
    return HttpAPI.get(`/video-tag`);
  }
  static page(
    page: number,
    pageSize: number,
    condition: Record<string, string | string[]>
  ): Promise<VideoTagListResultDto[]> {
    return HttpAPI.get(
      `/video-tag/${page}/${pageSize}?${stringify(condition)}`
    );
  }

  static count(condition: Record<string, string | string[]>) {
    return HttpAPI.get(`/video-tag/count?${stringify(condition)}`);
  }

  static getById(id: number) {
    return HttpAPI.get(`/video-tag/id/${id}`);
  }

  static create(data: VideoTagCreateDto) {
    return HttpAPI.post(`/video-tag`, data);
  }

  static updateById(id: number, data: VideoTagUpdateDto) {
    return HttpAPI.patch(`/video-tag/${id}`, data);
  }

  static removeById(id: number) {
    return HttpAPI.delete(`/video-tag/${id}`);
  }
}
