/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { MessageInstance } from "antd/es/message/interface";
import { Button, Dropdown, Input, MenuProps, Modal, Table } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import Editor from "./Editor/Editor";

import "./Article.scss";
import { ArticleListResultDto } from "../../../../API/Dto/article-list-result.dto";
import { ArticleType } from "../../../../API/Enum/article-type";
import { MessengerWrapper } from "../../../../Common/MessengerWrapper";
import ActionBar from "../../../../Layouts/LayoutHome/ActionBar/ActionBar";
import { ArticleAPI } from "../../../../API/article.api";
import { ArticleResultDto } from "../../../../API/Dto/article-result.dto";
import { SearchOutlined } from "@ant-design/icons";

export default function Article(props: { messenger: MessageInstance }) {
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(15);
  const [articleCount, setArticleCount] = useState<number>(0);

  const [articles, setArticles] = useState<ArticleListResultDto[]>([]);

  const [showEditor, setShowEditor] = useState<boolean>(false);
  const [editorType, setEditorType] = useState<ArticleType>(ArticleType.Text);
  const [editing, setEditing] = useState<ArticleResultDto>();

  const [searchCondition, setSearchCondition] = useState<Record<string, string | string[]>>({});
  async function search(condition: Record<string, any[]>) {
    const newCondition: Record<string, string | string[]> = {};
    if (condition.title) newCondition.title = condition.title[0];
    if (condition.type) newCondition.type = condition.type;
    setSearchCondition({ ...newCondition });
  }

  useEffect(() => {
    refresh();
  }, [page, pageSize, searchCondition]);

  async function refresh() {
    setShowEditor(false);

    MessengerWrapper(props.messenger, async () => {
      if (1 == page) {
        setArticleCount(await ArticleAPI.count(searchCondition));
      }
      setArticles(await ArticleAPI.page(page, pageSize, searchCondition));
    });
  }

  async function create(type: ArticleType) {
    setShowEditor(true);
    setEditorType(type);
    setEditing(undefined);
    return;
  }

  async function publish(record: ArticleListResultDto) {
    Modal.confirm({
      title: "确认发布?",
      content: `此操作会导致 ${record.title} 成为公开访问的文章`,
      onOk: () => {
        MessengerWrapper(props.messenger, async () => {
          await ArticleAPI.publish(record.id);
          refresh();
        });
      },
    });
  }

  async function unpublish(record: ArticleListResultDto) {
    Modal.confirm({
      title: "确认撤回?",
      content: `此操作会导致 ${record.title} 不再可访问`,
      onOk: () => {
        MessengerWrapper(props.messenger, async () => {
          await ArticleAPI.unpublish(record.id);
          refresh();
        });
      },
    });
  }

  async function edit(record: ArticleListResultDto) {
    setEditing(await ArticleAPI.getById(record.id));
    setEditorType(record.type);
    setShowEditor(true);
  }

  function remove(record: ArticleListResultDto): void {
    Modal.confirm({
      title: "确认删除?",
      content: `此操作将不可恢复的删除 ${record.title}`,
      onOk: () => {
        MessengerWrapper(props.messenger, async () => {
          await ArticleAPI.removeById(record.id);
          refresh();
        });
      },
    });
  }

  return (
    <div>
      <ActionBar>
        <Button className="add" type="primary" onClick={() => create(ArticleType.Text)}>
          创建新文章
        </Button>
        <Button className="add" type="primary" onClick={() => create(ArticleType.Video)}>
          创建新视频
        </Button>

      </ActionBar>
      <Table
        rowKey={"id"}
        dataSource={articles}
        onChange={(p, f: Record<string, any>) => p.current === page && search(f)}
        columns={[
          {
            key: "title",
            title: "标题",
            dataIndex: "title",
            filterDropdown: ({ selectedKeys, setSelectedKeys, confirm, clearFilters, close }) => (
              <div className="table-search">
                <Input
                  placeholder="搜索名称"
                  value={selectedKeys[0]}
                  onChange={(e) => setSelectedKeys((e.target.value ? [e.target.value] : []) as any)}
                />
                <Button
                  type="primary"
                  className="search-button"
                  onClick={() => confirm()}
                >
                  搜索
                </Button>
                <Button
                  type="default"
                  className="reset-button"
                  onClick={() => {
                    clearFilters!();
                  }}
                >
                  重置
                </Button>
                <Button
                  type="link"
                  className="close-button"
                  onClick={() => {
                    close();
                  }}
                >
                  关闭
                </Button>
              </div>
            ),
            filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />,
          },
          {
            key: "type",
            title: "类型",
            dataIndex: "type",
            filters: [
              {
                text: '文章',
                value: ArticleType.Text,
              },
              {
                text: '视频',
                value: ArticleType.Video,
              },
            ],
            render: (value) =>
            ((
              {
                [ArticleType.Text]: "文章",
                [ArticleType.Video]: "视频",
              } as any
            )[value]),
            width: 100,
          },
          { key: "sort", title: "优先级", dataIndex: "sort", width: 100 },
          {
            key: "publishedAt",
            title: "发布时间",
            dataIndex: "publishedAt",
            width: 200,
            render: (value, record, index) =>
              null === value
                ? "尚未发布"
                : moment(value).format("YYYY-MM-DD HH:mm:ss"),
          },
          {
            key: "action",
            title: "操作",
            dataIndex: "id",
            width: 60,
            render: (value, record, index) => {
              const items: MenuProps['items'] = [
                (!record.publishedAt ? {
                  key: 'publish',
                  label: "发布",
                  onClick: () => publish(record),
                } :
                  {
                    key: 'unpublish',
                    label: "撤回",
                    onClick: () => unpublish(record),
                  }),
                {
                  key: 'edit',
                  label: "编辑",
                  onClick: () => edit(record),
                },
                {
                  key: 'remove',
                  label: "删除",
                  onClick: () => remove(record),
                },
              ];

              return <Dropdown menu={{ items }}>
                <a onClick={(e) => e.preventDefault()} className="table-action-more"></a>
              </Dropdown>;
            },
          },
        ]}
        pagination={{
          pageSize,
          total: articleCount,
          pageSizeOptions: [10, 15, 20, 50, 100],
          showSizeChanger: true,
          position: ["bottomRight"],
          onChange: (page, pageSize) => {
            setPage(page);
            setPageSize(pageSize);
          },
        }}
      ></Table>
      {showEditor && (
        <Editor
          messenger={props.messenger}
          type={editorType}
          editing={editing}
          onDone={() => refresh()}
        />
      )}
    </div>
  );
}
