/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { MessageInstance } from "antd/es/message/interface";

export async function MessengerWrapper(
  messenger: MessageInstance,
  method: (...args: any[]) => any | Promise<any>,
  loadingText = "正在处理...",
  rethrow = false
) {
  const loadingKey = "loading-message-" + Date.now();

  try {
    messenger.open({
      key: loadingKey,
      type: "loading",
      content: loadingText,
      duration: 0,
    });
    return await method();
  } catch (e: any) {
    messenger.open({
      className: "global-error-toast",
      content: e.data.message,
    });
    if (rethrow) throw e;
  } finally {
    messenger.destroy(loadingKey);
  }
}
