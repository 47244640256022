/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { Button, Form, Input, Modal, Select } from "antd";

import { MessageInstance } from "antd/es/message/interface";

import ModalEditor from "../../../../../Layouts/ModalEditor/ModalEditor";
import { UserGender } from "../../../../../API/Enum/user-gender";
import { UserCreateDto } from "../../../../../API/Dto/user-create.dto";
import { UserAPI } from "../../../../../API/User.api";
import { UserResultDto } from "../../../../../API/Dto/user-result.dto";
import { HttpAPI } from "../../../../../API/HttpAPI";

export default function Editor(props: {
  messenger: MessageInstance;
  editing?: UserResultDto;
  onDone: () => void;
}) {


  return (
    <ModalEditor
      messenger={props.messenger}
      editing={props.editing}
      name={"用户"}
      onDone={props.onDone}
      onBeforeSubmit={async (d) => {
        return new Promise((r, e) => {
          if (props.editing) {
            r(true);
            return;
          }
          Modal.confirm({
            title: "确认创建",
            content: "即将创建1个用户",
            onOk: async () => {
              r(true);
            },
            onCancel: async () => {
              r(false);
            },
          });
        });
      }}
      onSubmit={async (d) => {
        const formData: UserCreateDto = {
          name: d.name,
          countryCode: "86",
          mobile: d.mobile,
          organizationId: HttpAPI.getOrganization(),
          gender: d.gender,
        };
        await (props.editing
          ? UserAPI.updateById(props.editing.id, formData)
          : UserAPI.create(formData));
        Modal.success({
          title: `${props.editing ? "编辑" : "创建"}完成`,
          content: `姓名: ${d.name}, 手机号: ${d.mobile}, 性别: ${d.gender === UserGender.MALE ? '男' : '女'}`,
        });
      }}
    >
      <div className="form-row">
        <Form.Item
          label="姓名"
          name="name"
          rules={[{ required: true, message: "请输入姓名" }]}
        >
          <Input />
        </Form.Item>
        <div className="form-row-expanded">
          <Form.Item
            label="手机号"
            name="mobile"
            rules={[{ required: true, message: "请输入手机号" }]}
          >
            <Input />
          </Form.Item>
        </div>
        <Form.Item
          label="性别"
          name="gender"
          rules={[{ required: true, message: "请选择性别" }]}
          initialValue={UserGender.MALE}
        >
          <Select className="gender">
            <Select.Option value={UserGender.MALE}>男</Select.Option>
            <Select.Option value={UserGender.FEMALE}>女</Select.Option>
          </Select>
        </Form.Item>
      </div>
      <Form.Item className="save-button">
        <Button type="primary" htmlType="submit">
          {props.editing ? "确认修改" : "确认创建"}
        </Button>
      </Form.Item>
    </ModalEditor>
  );
}
