/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { MessageInstance } from "antd/es/message/interface";
import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  InputNumber,
  Select,
  TreeSelect,
  Upload,
} from "antd";
import { useEffect, useState } from "react";
import { RcFile } from "antd/es/upload";
import { Editor as WangEditor, Toolbar } from "@wangeditor/editor-for-react";
import "@wangeditor/editor/dist/css/style.css";

import "./Editor.scss";
import { ArticleResultDto } from "../../../../../API/Dto/article-result.dto";
import { ArticleType } from "../../../../../API/Enum/article-type";
import ModalEditor from "../../../../../Layouts/ModalEditor/ModalEditor";
import { ArticleAPI } from "../../../../../API/article.api";
import { CategoryAPI } from "../../../../../API/category.api";
import { CategoryTreeResultDto } from "../../../../../API/Dto/category-tree-result.dto";
import { OSSApi } from "../../../../../API/oss.api";
import { MessengerWrapper } from "../../../../../Common/MessengerWrapper";
import { ArticleCreateDto } from "../../../../../API/Dto/article-create.dto";
import { IDomEditor, IToolbarConfig, IEditorConfig } from "@wangeditor/editor";

export default function Editor(props: {
  type: ArticleType;
  editing?: ArticleResultDto;
  messenger: MessageInstance;
  onDone: () => void;
}) {
  const typeString = {
    [ArticleType.Text]: "文章",
    [ArticleType.Video]: "视频",
  }[props.type];

  const [editor, setEditor] = useState<IDomEditor | null>(null);
  const [editing, setEditing] = useState<
    (Omit<ArticleResultDto, "categories"> & { categories: number[] }) | null
  >(null);

  const toolbarConfig: Partial<IToolbarConfig> = {
    excludeKeys: [
      "codeBlock",
      "code",
      "todo",
      "divider",
      "emotion",
      "undo",
      "redo",
    ],
  };
  const editorConfig: Partial<IEditorConfig> = {
    MENU_CONF: {
      uploadImage: {
        async customUpload(file: File, insertFn: (...args: any[]) => void) {
          MessengerWrapper(props.messenger, async () => {
            insertFn(await OSSApi.upload(file));
          });
        },
      },
      uploadVideo: {
        async customUpload(file: File, insertFn: (...args: any[]) => void) {
          MessengerWrapper(props.messenger, async () => {
            insertFn(await OSSApi.upload(file));
          });
        },
      },
    },
  };

  const [categories, setCategories] = useState<CategoryTreeResultDto[]>([]);
  async function refresh() {
    MessengerWrapper(props.messenger, async () => {
      setCategories(await CategoryAPI.getTree());
    });
  }
  useEffect(() => {
    refresh();
  }, []);

  useEffect(() => {
    if (props.editing) {
      editor?.setHtml(props.editing?.rawContent || "");
      setEditing({
        ...props.editing,
        categories: props.editing.categories.map((v) => v.category.id),
      });
    }
  }, [props.editing, editor]);

  const [coverFile, setCoverFile] = useState<RcFile>();
  const [videoFile, setVideoFile] = useState<RcFile>();

  async function done() {
    setCoverFile(undefined);
    setVideoFile(undefined);
    editor?.setHtml("");
    props.onDone();
  }

  async function preventUpload(setFunc: (f: RcFile) => void, file: RcFile) {
    setFunc(file);
    return false;
  }

  return (
    <>
      <ModalEditor
        messenger={props.messenger}
        name={typeString}
        onDone={done}
        editing={editing}
        onSubmit={async (d) => {
          const formData: ArticleCreateDto = {
            title: d.title,
            subtitle: d.subtitle || "",
            author: d.author || "",
            editor: d.editor || "",
            reference: d.reference || "",
            tags: (d.tags || []).filter((v: any) => v),
            type: props.type,
            sort: d.sort,
            categories: d.categories,
            coverImageUrl: d.cover
              ? await OSSApi.upload(d.cover.file)
              : props.editing?.coverImageUrl || "",
            videoUrl: d.video
              ? await OSSApi.upload(d.video.file)
              : props.editing?.videoUrl || "",
            rawContent: editor?.getHtml() || "",
          };

          if (props.editing) {
            await ArticleAPI.updateById(props.editing.id, formData);
          } else {
            await ArticleAPI.create(formData);
          }
        }}
      >
        <Form.Item
          label="标题"
          name="title"
          rules={[{ required: true, message: "不可为空" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="副标题"
          name="subtitle"
          rules={[{ required: false, message: "不可为空" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="标签"
          name="tags"
          rules={[{ required: false, message: "不可为空" }]}
        >
          <Select mode="tags">
            <Select.Option value="活动">活动</Select.Option>
            <Select.Option value="文章">文章</Select.Option>
            <Select.Option value="视频">视频</Select.Option>
          </Select>
        </Form.Item>
        <div className={`form-row`}>
          <Form.Item
            label="责编"
            name="editor"
            rules={[
              {
                required: false,
                message: "不可为空",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <div className="form-row-expanded">
            <Form.Item
              label="来源"
              name="reference"
              rules={[{ required: false, message: "不可为空" }]}
            >
              <Input />
            </Form.Item>
          </div>
          <Form.Item
            label="作者"
            name="author"
            rules={[{ required: false, message: "不可为空" }]}
          >
            <Input />
          </Form.Item>
        </div>
        <Form.Item
          label="分类"
          name="categories"
          rules={[{ required: true, message: "至少选择一项" }]}
        >
          <TreeSelect
            className="categoryIds"
            dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
            showSearch
            allowClear
            multiple
            treeDefaultExpandAll
            showCheckedStrategy={TreeSelect.SHOW_ALL}
            treeData={categories}
            fieldNames={{
              label: "display",
              value: "id",
            }}
          />
        </Form.Item>
        <div className={`form-row`}>
          <Form.Item
            label="封面"
            name="cover"
            rules={[
              {
                required: false,
                message: "请选择文件",
              },
            ]}
          >
            <Upload
              accept=".jpg, .png, .jpeg"
              listType="picture-card"
              className="album-uploader"
              showUploadList={false}
              beforeUpload={(f) => preventUpload(setCoverFile, f)}
            >
              <div className="previewer">
                {coverFile || props.editing ? (
                  <img
                    src={
                      coverFile
                        ? window.URL.createObjectURL(new Blob([coverFile]))
                        : props.editing?.coverImageUrl
                    }
                  />
                ) : (
                  <div>
                    <div style={{ marginTop: 8 }}>
                      <PlusOutlined /> 点击上传封面
                    </div>
                  </div>
                )}
              </div>
            </Upload>
          </Form.Item>
          <div className="form-row-expanded">
            <Form.Item
              label="优先级"
              name="sort"
              rules={[
                {
                  required: true,
                  message: "不可为空",
                },
              ]}
              initialValue={0}
            >
              <InputNumber />
            </Form.Item>
          </div>
        </div>
        <div className={"rich-editor"} hidden={ArticleType.Text !== props.type}>
          <Toolbar
            editor={editor}
            defaultConfig={toolbarConfig}
            mode="default"
            style={{ borderBottom: "1px solid #ccc" }}
          />
          <WangEditor
            defaultConfig={editorConfig}
            onCreated={setEditor}
            mode="default"
            style={{ height: "500px", overflowY: "hidden" }}
          />
        </div>
        <Form.Item
          label="视频"
          name="video"
          rules={[
            {
              required: !props.editing && ArticleType.Video === props.type,
              message: "请选择文件",
            },
          ]}
          hidden={ArticleType.Video !== props.type}
        >
          <Upload
            accept=".mp4"
            listType="picture-card"
            className="album-uploader"
            showUploadList={false}
            beforeUpload={(f) => preventUpload(setVideoFile, f)}
          >
            <div className="previewer">
              {videoFile || props.editing ? (
                <video
                  src={
                    videoFile
                      ? window.URL.createObjectURL(new Blob([videoFile]))
                      : props.editing?.videoUrl
                  }
                  autoPlay={props.editing ? false : true}
                />
              ) : (
                <div>
                  <PlusOutlined /> 点击上传视频
                </div>
              )}
            </div>
          </Upload>
        </Form.Item>
        <Form.Item className="save-button">
          <Button type="primary" htmlType="submit">
            {props.editing ? `保存编辑` : `创建新草稿`}
          </Button>
        </Form.Item>
      </ModalEditor>
    </>
  );
}
