/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { Button, Modal, Table, Tabs } from "antd";
import { useEffect, useState } from "react";
import { MessageInstance } from "antd/es/message/interface";
import Editor from "./Editor/Editor";
import ActionBar from "../../../../Layouts/LayoutHome/ActionBar/ActionBar";
import moment from "moment";
import { MessengerWrapper } from "../../../../Common/MessengerWrapper";
import { NotificationAPI } from "../../../../API/notification.api";
import { NotificationListResultDto } from "../../../../API/Dto/notification-list-result.dto";
import './Notification.scss';

export default function Notification(props: { messenger: MessageInstance }) {
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(15);
  const [scope, setScope] = useState<string>("Global");

  const [rows, setRows] = useState<NotificationListResultDto[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);

  const [showEditor, setShowEditor] = useState<boolean>(false);
  const [editingType, setEditingType] = useState<string>("");

  useEffect(() => {
    refresh();
  }, [page, pageSize, scope]);

  useEffect(() => {
    setPage(1);
  }, [scope]);

  async function refresh() {
    setShowEditor(false);

    MessengerWrapper(props.messenger, async () => {
      switch (scope) {
        case "Global":
          if (1 === page) {
            setRowCount(await NotificationAPI.countGlobalNotification());
          }
          setRows(await NotificationAPI.pageGlobalNotification(page, pageSize));
          break;
        case "Organization":
          if (1 === page) {
            setRowCount(await NotificationAPI.countOrganizationNotification());
          }
          setRows(
            await NotificationAPI.pageOrganizationNotification(page, pageSize)
          );
          break;
        case "User":
          if (1 === page) {
            setRowCount(await NotificationAPI.countUserNotification());
          }
          setRows(await NotificationAPI.pageUserNotification(page, pageSize));
          break;
      }
    });
  }

  function createGlobal() {
    setEditingType("Global");
    setShowEditor(true);
  }

  function createOrganization() {
    setEditingType("Organization");
    setShowEditor(true);
  }

  // function createUser() {
  //   setEditingType("User");
  //   setShowEditor(true);
  // }

  function remove(record: NotificationListResultDto) {
    Modal.confirm({
      title: "确认删除?",
      content: `此操作将不可恢复的删除 ${record.title}`,
      onOk: () => {
        MessengerWrapper(props.messenger, async () => {
          if (record.user) {
            await NotificationAPI.removeUserNotificationById(record.id);
          } else if (record.organization) {
            await NotificationAPI.removeOrganizationNotificationById(record.id);
          } else {
            await NotificationAPI.removeGlobalNotificationById(record.id);
          }
          refresh();
        });
      },
    });
  }

  return (
    <div>
      <ActionBar>
        <Button className="add" type="primary" onClick={() => createGlobal()}>
          {"创建全局通知"}
        </Button>
        <Button className="add" type="primary" onClick={() => createOrganization()}>
          {"创建组织通知"}
        </Button>
        {/* <Button className="add" type="primary" onClick={() => createUser()}>
          {"创建用户通知"}
        </Button> */}
      </ActionBar>
      <Tabs
        defaultActiveKey={scope}
        className="scope-tab"
        items={[
          {
            key: "Global",
            label: "全局",
          },
          {
            key: "Organization",
            label: "组织",
          },
          // {
          //   key: "User",
          //   label: "用户",
          // },
        ]}
        onChange={(key) => setScope(key)}
      />
      <Table
        rowKey={"id"}
        dataSource={rows}
        columns={[
          {
            key: "title",
            title: "标题",
            dataIndex: "title",
            width: 300,
          },
          {
            key: "content",
            title: "内容",
            dataIndex: "content",
          },
          {
            key: "type",
            title: "类型",
            dataIndex: "type",
            render: (value: string, record) =>
            ({
              Video: "幸福大讲堂",
              System: "系统",
              Consulting: "咨询",
            }[value]),
            width: 120,
          },
          {
            key: "target",
            title: "接收人",
            dataIndex: "id",
            render: (value, record) => {
              switch (scope) {
                case "Global":
                  return "全局";
                case "Organization":
                  return record.organization?.name;
                case "User":
                  return record.user?.name;
              }
            },
            width: 100,
          },
          {
            key: "createdAt",
            title: "发送于",
            dataIndex: "sentAt",
            render: (value) => moment(value).format("YYYY-MM-DD HH:mm:ss"),
            width: 200,
          },
          {
            key: "action",
            title: "操作",
            dataIndex: "id",
            width: 100,
            render: (value, record, index) => (
              <>
                <Button type="link" onClick={() => remove(record)}>
                  删除
                </Button>
              </>
            ),
          },
        ]}
        pagination={{
          pageSize,
          total: rowCount,
          pageSizeOptions: [10, 15, 20, 50, 100],
          showSizeChanger: true,
          position: ["bottomRight"],
          onChange: (page, pageSize) => {
            setPage(page);
            setPageSize(pageSize);
          },
        }}
      ></Table>
      {showEditor && (
        <Editor
          messenger={props.messenger}
          type={editingType}
          onDone={() => refresh()}
        />
      )}
    </div>
  );
}
