/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { DateComplainResultDto } from "./Dto/date-complain-result.dto";
import { HttpAPI } from "./HttpAPI";

export class DateComplainAPI {
  static async page(
    page: number,
    size: number
  ): Promise<DateComplainResultDto[]> {
    return HttpAPI.get(`/date/complain/${page}/${size}`);
  }

  static async count(): Promise<number> {
    return HttpAPI.get(`/date/complain/count`);
  }
}
