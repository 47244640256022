/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { Button, Input } from "antd";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MessageInstance } from "antd/es/message/interface";
import { AuthAPI } from "../../../API/Auth.api";
import LayoutLogin from "../../../Layouts/LayoutLogin/LayoutLogin";

export default function Initial(props: { messenger: MessageInstance }) {
  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(
    () => setIsDisabled(!(password && confirmPassword)),
    [password, confirmPassword]
  );

  async function performInitial() {
    const passwordContainsCount =
      (/[0-9]/.test(password) ? 1 : 0) +
      (/[a-z]/.test(password) ? 1 : 0) +
      (/[A-Z]/.test(password) ? 1 : 0) +
      (/[^0-9a-zA-Z]/.test(password) ? 1 : 0);

    if (!/.{8,16}/.test(password) || 3 > passwordContainsCount) {
      props.messenger.open({
        content: "密码必须为：8-16 位大小写字母、数字、符号 3 种以上组合",
        className: "global-error-toast",
        duration: 1,
      });
      return;
    }

    if (password !== confirmPassword) {
      props.messenger.open({
        content: "两次密码不一致",
        className: "global-error-toast",
        duration: 1,
      });
      return;
    }

    try {
      await AuthAPI.initialPassword(password);
      navigate("/login/initial/done");
    } catch (e) {
      props.messenger.open({
        className: "global-error-toast",
        content: (e as Error).message,
        duration: 1,
      });
    }
  }

  return (
    <LayoutLogin>
      <div className="legend">首次登陆请重置密码</div>
      <div className="input-group">
        <div className="label">新的密码</div>
        <Input.Password
          placeholder="8-16位，大小写字母、数字、符号"
          value={password}
          onInput={(v) => setPassword((v.target as HTMLInputElement).value)}
        />
      </div>
      <div className="input-group">
        <div className="label">确认密码</div>
        <Input.Password
          placeholder="8-16位，大小写字母、数字、符号"
          value={confirmPassword}
          onInput={(v) =>
            setConfirmPassword((v.target as HTMLInputElement).value)
          }
        />
      </div>
      <div className="button-link-group">
        <div className="button-group">
          <Button
            className={"submit-button"}
            onClick={performInitial}
            disabled={isDisabled}
          >
            确认
          </Button>
        </div>
        <div className="link-group">
          <Link className="link" to={"/login"}>
            取消
          </Link>
        </div>
      </div>
    </LayoutLogin>
  );
}
