/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { Button, Form, Input, Select } from "antd";

import { MessageInstance } from "antd/es/message/interface";

import "./Editor.scss";
import ModalEditor from "../../../../../Layouts/ModalEditor/ModalEditor";
import { DateTagFamilyResultDto } from "../../../../../API/Dto/date-tag-family-result.dto";
import { useState, useEffect } from "react";
import { MessengerWrapper } from "../../../../../Common/MessengerWrapper";
import { DateTagOptionAPI } from "../../../../../API/date-tag-option.api";
import { DateTagOptionCreateDto } from "../../../../../API/Dto/date-tag-option-create.dto";
import { DateTagFamilyAPI } from "../../../../../API/date-tag-family.api";

export default function Editor(props: {
  messenger: MessageInstance;
  editing?: DateTagFamilyResultDto;
  onDone: () => void;
}) {

  function done() {
    props.onDone();
  }

  const [families, setFamilies] = useState<DateTagFamilyResultDto[]>([]);

  useEffect(() => {
    refresh();
  }, []);

  async function refresh() {

    MessengerWrapper(props.messenger, async () => {
      const families = await DateTagFamilyAPI.get();
      setFamilies(families);
    });
  }

  return (
    <ModalEditor
      messenger={props.messenger}
      editing={props.editing}
      name="标签族"
      onDone={done}
      onSubmit={async (d) => {
        const formData: DateTagOptionCreateDto = {
          display: d.display,
          key: d.key,
          familyId: d.familyId,
        };

        if (props.editing) {
          await DateTagOptionAPI.updateById(props.editing.id, formData);
        } else {
          await DateTagOptionAPI.create(formData);
        }
      }}
    >
      <Form.Item
        label="标签族"
        name="familyId"
        rules={[{ required: true, message: "请输入标签族" }]}
      >
        <Select options={families.map(v => ({ value: v.id, label: v.display }))}></Select>
      </Form.Item>
      <Form.Item
        label="名称"
        name="display"
        rules={[{ required: true, message: "请输入名称" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="标识符"
        name="key"
        rules={[{ required: true, message: "请输入标识符" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item className="save-button">
        <Button type="primary" htmlType="submit">
          {props.editing ? "确认修改" : "确认创建"}
        </Button>
      </Form.Item>
    </ModalEditor>
  );
}
