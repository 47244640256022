/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { useState, useEffect } from "react";
import { Button, Input } from "antd";
import { MessageInstance } from "antd/es/message/interface";
import LayoutLogin from "../../Layouts/LayoutLogin/LayoutLogin";
import { AuthAPI } from "../../API/Auth.api";
import { Link, useNavigate } from "react-router-dom";
import { MessengerWrapper } from "../../Common/MessengerWrapper";

export default function Login(props: { messenger: MessageInstance }) {
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);

  const navigate = useNavigate();

  useEffect(() => setIsDisabled(!mobile), [mobile]);
  async function performLogin() {
    MessengerWrapper(props.messenger, async () => {
      const mfaUser = await AuthAPI.prefetchCaptcha(mobile, password);
      if (1 === parseInt(localStorage[`${mfaUser.id}-trusted`])) {
        await AuthAPI.trustLogin();
        navigate(mfaUser.isInitialized ? "/home" : "/login/initial");
      } else {
        await AuthAPI.requestCaptcha(mobile, password);
        navigate("/login/mfa");
      }
    });
  }

  return (
    <LayoutLogin>
      <div className="legend">账号登录</div>
      <div className="input-group">
        <div className="label">手机号</div>
        <Input
          type="text"
          name="phone"
          placeholder="输入手机号"
          value={mobile}
          onInput={(v) => setMobile((v.target as HTMLInputElement).value)}
        />
      </div>
      <div className="input-group">
        <div className="label">密码</div>
        <Input
          type="password"
          name="password"
          placeholder="输入密码"
          value={password}
          onInput={(v) => setPassword((v.target as HTMLInputElement).value)}
        />
      </div>
      <div className="button-link-group">
        <div className="button-group"><Button className={"submit-button"} id="login" onClick={performLogin} disabled={isDisabled}>登录</Button></div>
        <div className="link-group"><Link className="link" to={'/login/recover-password'}>找回密码</Link></div>
      </div>
    </LayoutLogin>
  );
}
