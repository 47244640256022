/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { Form, FormInstance, Modal } from "antd";

import { MessageInstance } from "antd/es/message/interface";

import "./ModalEditor.scss";
import { useEffect, useRef } from "react";
import { MessengerWrapper } from "../../Common/MessengerWrapper";

export default function ModalEditor<T>(props: {
  width?: number;
  messenger: MessageInstance;
  name: string;
  className?: string;
  formRef?: React.RefObject<FormInstance<any>>;
  title?: string;
  subtitle?: string;
  editing?: T;
  children: React.ReactNode;
  onDone: () => void;
  onBeforeSubmit?: (d: any) => Promise<boolean>;
  onSubmit: (d: any) => Promise<void> | void;
  onValueChange?: (k: any, v: any) => void;
}) {
  const formRef = props.formRef || useRef<FormInstance>(null);
  useEffect(() => {
    formRef.current?.setFieldsValue(props.editing);
  }, [props.editing]);

  async function submit(d: any) {
    if (props.onBeforeSubmit) {
      if (!(await props.onBeforeSubmit(d))) return;
    }
    MessengerWrapper(props.messenger, async () => {
      await props.onSubmit(d);
      done();
    });
  }

  async function done() {
    formRef.current?.resetFields();
    props.onDone();
  }

  return (
    <Modal
      className={`modal-editor ${props.className}`}
      open={true}
      onCancel={done}
      footer={false}
      maskClosable={false}
      title={
        props.title ||
        (props.editing ? `编辑${props.name}` : `创建新${props.name}`)
      }
      width={props.width || 800}
    >
      <div className="subtitle">{props.subtitle}</div>
      <Form
        onFinish={(d) => submit(d)}
        ref={formRef}
        onValuesChange={(k, v) =>
          props.onValueChange && props.onValueChange(k, v)
        }
      >
        {props.children}
      </Form>
    </Modal>
  );
}
