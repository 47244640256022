/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { Button, Form, Input, Select } from "antd";

import { MessageInstance } from "antd/es/message/interface";

import ModalEditor from "../../../../../Layouts/ModalEditor/ModalEditor";
import TextArea from "antd/es/input/TextArea";
import { useEffect, useState } from "react";
import { OrganizationListResultDto } from "../../../../../API/Dto/organization-list-result.dto";
import { OrganizationAPI } from "../../../../../API/organization.api";
import { UserAPI } from "../../../../../API/User.api";
import { UserResultDto } from "../../../../../API/Dto/user-result.dto";
import { NotificationAPI } from "../../../../../API/notification.api";
import { NotificationCreateDto } from "../../../../../API/notification-create.dto";

export default function Editor(props: {
  messenger: MessageInstance;
  type: string;
  onDone: () => void;
}) {
  const [organizations, setOrganizations] = useState<
    OrganizationListResultDto[]
  >([]);
  const [searchUsers, setSearchUsers] = useState<UserResultDto[]>([]);

  useEffect(() => {
    _refreshType();
  }, [props.type]);

  async function _refreshType() {
    switch (props.type) {
      case "Organization":
        setOrganizations(await OrganizationAPI.get());
        break;
    }
  }

  async function _userSearch(v: string) {
    if ("" == v) return;
    setSearchUsers(await UserAPI.searchByMobile(v));
  }

  return (
    <ModalEditor
      messenger={props.messenger}
      name={"通知"}
      onDone={props.onDone}
      onSubmit={async (d: any) => {
        const formData: NotificationCreateDto = {
          title: d.title,
          content: d.content,
          type: d.type,
        };

        if (d.userIds) {
          formData.userIds = d.userIds;
        }

        switch (props.type) {
          case "User":
            await NotificationAPI.createUserNotification(formData);
            break;
          case "Organization":
            await NotificationAPI.createOrganizationNotification(
              d.organizationId,
              formData
            );
            break;
          case "Global":
            await NotificationAPI.createGlobalNotification(formData);
            break;
        }
      }}
    >
      <Form.Item
        hidden={"Organization" !== props.type}
        label="组织"
        name="organizationId"
        rules={[
          { required: "Organization" === props.type, message: "请选择组织" },
        ]}
      >
        <Select
          options={organizations.map((v) => ({ label: v.name, value: v.id }))}
        />
      </Form.Item>
      <Form.Item
        hidden={"User" !== props.type}
        label="用户"
        name="userIds"
        rules={[
          { required: "User" === props.type, message: "请选择至少一位用户" },
        ]}
      >
        <Select
          showSearch
          mode="multiple"
          placeholder="通过手机号搜索用户"
          showArrow={false}
          filterOption={false}
          onSearch={_userSearch}
          notFoundContent={null}
          options={searchUsers.map((d) => ({
            value: d.id,
            label: `${d.name} - ${d.mobile}`,
          }))}
        />
      </Form.Item>
      <Form.Item
        label="标题"
        name="title"
        rules={[{ required: true, message: "请输入标题" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="内容"
        name="content"
        rules={[{ required: true, message: "请输入内容" }]}
      >
        <TextArea rows={3} />
      </Form.Item>
      <Form.Item
        label="类型"
        name="type"
        rules={[{ required: true, message: "请选择类型" }]}
        initialValue={"System"}
      >
        <Select>
          <Select.Option value="System">系统</Select.Option>
          {/* <Select.Option value="Consulting">咨询</Select.Option> */}
          <Select.Option value="Video">幸福大讲堂</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item className="save-button">
        <Button type="primary" htmlType="submit">
          确认创建
        </Button>
      </Form.Item>
    </ModalEditor>
  );
}
