/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { Button } from "antd";
import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import PinInput from 'react-pin-input';
import { MessageInstance } from "antd/es/message/interface";
import LayoutLogin from "../../../../Layouts/LayoutLogin/LayoutLogin";
import { AuthAPI } from "../../../../API/Auth.api";


export default function Captcha(props: { messenger: MessageInstance }) {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const mobile = searchParams.get('mobile')!;
  if (null === mobile) navigate('/login/recover-password');

  const [countDown, setCountDown] = useState(60);
  const [captcha, setCaptcha] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => setIsDisabled(6 !== captcha.length), [captcha]);

  async function performReset() {
    try {
      await AuthAPI.prepareRecover(mobile, captcha);
      navigate('/login/recover-password/captcha/reset')
    } catch (e) {
      props.messenger.open({
        className: 'global-error-toast',
        content: (e as Error).message,
        duration: 1,
      });
    }
  }

  useEffect(() => startTimer(), [countDown])

  function startTimer() {
    setTimeout(() => {
      if ((countDown - 1) >= 0) setCountDown(countDown - 1);
    }, 1000)
  }

  async function performResend() {
    if (0 < countDown) return;

    try {
      await AuthAPI.requestCaptchaByRecover(mobile);
      setCountDown(60);
    } catch (e: any) {
      props.messenger.open({
        className: 'global-error-toast',
        content: e?.data?.message,
        duration: 3,
      });
    }
  }

  return <LayoutLogin>
    <div className="legend compact">请输入手机验证码</div>
    <div className="description">
      * 验证码已发送至 +86 {mobile}，请查收。<br />
      * 如验证码接收或使用遇到问题请联系你的<b>客服</b>。
    </div>
    <div className="input-group">
      <div className="label">验证码</div>
      <PinInput
        length={6}
        type="numeric"
        inputMode="number"
        onChange={(value) => setCaptcha(value)}
        autoSelect={true}
        regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
      />
    </div>
    <div className="link-group">
      <Button type="link" className="link" onClick={performResend}>
        重新发送{countDown ? `(${countDown})` : ''}
      </Button>
    </div>
    <div className="button-link-group">
      <div className="button-group"><Button className={"submit-button"} onClick={performReset} disabled={isDisabled}>确认</Button></div>
      <div className="link-group"><Link className="link" to={'/login'}>取消</Link></div>
    </div>
  </LayoutLogin>
}
