/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { message } from "antd";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import "./App.scss";
import Login from "../Pages/Login/Login";
import Home from "../Pages/Home/Home";
import LoginMFA from "../Pages/Login/MFA";
import LoginInitial from "./Login/Initial/Initial";
import LoginDone from "./Login/Initial/Done/Done";
import LoginTrust from "./Login/Trust/Trust";
import LoginRecoverPassword from "./Login/RecoverPassword/RecoverPassword";
import LoginRecoverPasswordDone from "./Login/RecoverPassword/Done/Done";
import LoginRecoverPasswordCaptcha from "./Login/RecoverPassword/Captcha/Captcha";
import LoginRecoverPasswordCaptchaReset from "./Login/RecoverPassword/Captcha/Reset/Reset";

// Fix
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

function App() {
  const [messenger, contextHolder] = message.useMessage();

  return (
    <>
      {contextHolder}
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<Navigate to={"/home"} replace />} />
          <Route path="/login" element={<Login messenger={messenger} />} />
          <Route
            path="/login/mfa"
            element={<LoginMFA messenger={messenger} />}
          />
          <Route
            path="/login/trust"
            element={<LoginTrust messenger={messenger} />}
          />
          <Route
            path="/login/initial"
            element={<LoginInitial messenger={messenger} />}
          />
          <Route
            path="/login/initial/done"
            element={<LoginDone messenger={messenger} />}
          />
          <Route
            path="/login/recover-password"
            element={<LoginRecoverPassword messenger={messenger} />} />
          <Route
            path="/login/recover-password/captcha"
            element={<LoginRecoverPasswordCaptcha messenger={messenger} />} />
          <Route
            path="/login/recover-password/captcha/reset"
            element={<LoginRecoverPasswordCaptchaReset messenger={messenger} />} />
          <Route
            path="/login/recover-password/done"
            element={<LoginRecoverPasswordDone messenger={messenger} />} />
          <Route
            path="/home/*"
            element={<Home messenger={messenger} />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
