/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { MessageInstance } from "antd/es/message/interface";
import ActionBar from "../../../../Layouts/LayoutHome/ActionBar/ActionBar";
import { Button, Form, FormInstance, Input, Upload } from "antd";
import { useEffect, useRef, useState } from "react";
import { RcFile } from "antd/es/upload";
import { AuthAPI } from "../../../../API/Auth.api";

import "./Profile.scss";
import { SecretaryUpdateDto } from "../../../../API/Dto/secretary-update.dto";
import { OSSApi } from "../../../../API/oss.api";
import Password from "./Password/Password";
import Mobile from "../Mobile/Mobile";
import { SecretaryRole } from "../../../../API/Enum/secretary-role";
import { OrganizationListResultDto } from "../../../../API/Dto/organization-list-result.dto";
import { OrganizationAPI } from "../../../../API/organization.api";

export default function ConsultProfile(props: { messenger: MessageInstance }) {
  const [avatarFile, setAvatarFile] = useState<RcFile | null>(null);
  const formRef = useRef<FormInstance>(null);

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showMobile, setShowMobile] = useState<boolean>(false);
  const [organizations, setOrganizations] = useState<OrganizationListResultDto[]>([]);

  const user = AuthAPI.getLocalUser();


  useEffect(() => {
    refresh();
  }, []);

  async function refresh() {
    formRef.current?.setFieldsValue(user);
    if (SecretaryRole.BUTLER !== user?.role) return;
    setOrganizations(await OrganizationAPI.getRelated());
    return;
  }

  async function preventUpload(setFunc: (f: RcFile) => void, file: RcFile) {
    setFunc(file);
    return false;
  }

  async function submit(d: any) {
    const formData: SecretaryUpdateDto = {
      name: d.name,
      qualification: d.qualification,
      avatarUrl:
        "string" === typeof d.avatarUrl
          ? d.avatarUrl
          : await OSSApi.upload(d.avatarUrl.file),
    };
    await AuthAPI.update(formData);
    props.messenger.success("更新完成");
  }

  return (
    <div>
      <div className="consult-profile">
        <ActionBar></ActionBar>
        <div className="table-title">用户信息</div>
        <Form ref={formRef} onFinish={(d) => submit(d)} className="consult-form">
          <Form.Item
            label=""
            name="avatarUrl"
            rules={[{ required: SecretaryRole.CONSULTANT === user?.role, message: "请选择头像" }]}
            hidden={SecretaryRole.CONSULTANT !== user?.role}
          >
            <Upload
              accept=".jpg, .png, .jpeg"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              beforeUpload={(f) => preventUpload(setAvatarFile, f)}
            >
              <div className="previewer">
                {avatarFile || user?.avatarUrl ? (
                  <img
                    src={
                      avatarFile
                        ? window.URL.createObjectURL(new Blob([avatarFile]))
                        : user?.avatarUrl
                    }
                  />
                ) : (
                  <div className="avatar-placeholder">
                  </div>
                )}
              </div>
            </Upload>
          </Form.Item>
          <Form.Item
            label="姓名"
            name="name"
            rules={[{ required: true, message: "请输入姓名" }]}
          >
            <Input suffix={<Button type="text" htmlType="submit">保存</Button>} />
          </Form.Item>
          <Form.Item
            label="手机号码"
            name='mobile'
          >
            <Input readOnly suffix={<Button type="text" onClick={() => setShowMobile(true)}>更改</Button>} />
          </Form.Item>
          <Form.Item label="咨询类型" hidden={SecretaryRole.CONSULTANT !== user?.role}>
            {user?.consultantRole
              .map((v) => ({ LEGAL: "法律", PSYCHOLOGICAL: "心理" }[v]))
              .join(",")}
          </Form.Item>
          <Form.Item
            label="专业资质"
            hidden={SecretaryRole.CONSULTANT !== user?.role}
          >
            <div className="input-group">
              <Form.Item
                name="qualification"
                className="expanded"
                rules={[{ required: SecretaryRole.CONSULTANT === user?.role, message: "请输入专业资质" }]}
              >
                <Input.TextArea rows={5} />
              </Form.Item>
              <Button type="text" htmlType="submit">更改</Button>
            </div>
          </Form.Item>
          <Form.Item
            label="密码"
          >
            <Input value='···········' id="password" readOnly suffix={<Button type="text" onClick={() => setShowPassword(true)}>更改</Button>} />
          </Form.Item>
          <Form.Item
            label="所属公司（请联系客服更改）"
            hidden={SecretaryRole.BUTLER !== user?.role}
          >
            <Input value={organizations.map(v => v.name).join(', ')} readOnly />
          </Form.Item>
        </Form>
      </div>
      {
        showPassword &&
        <Password
          messenger={props.messenger}
          onDone={() => setShowPassword(false)}
        />
      }
      {
        showMobile &&
        <Mobile
          messenger={props.messenger}
          onDone={() => setShowMobile(false)}
        />
      }
    </div>
  );
}
