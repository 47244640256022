/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { Button } from "antd";
import { AuthAPI } from "../../API/Auth.api";
import LayoutLogin from "../../Layouts/LayoutLogin/LayoutLogin";
import { Link, useNavigate } from "react-router-dom";
import PinInput from "react-pin-input";
import { MessageInstance } from "antd/es/message/interface";
import { useState, useEffect } from "react";
import { MessengerWrapper } from "../../Common/MessengerWrapper";

export default function MFA(props: { messenger: MessageInstance }) {
  const navigate = useNavigate();

  const user = AuthAPI.getLocalUser();
  const [countDown, setCountDown] = useState(0);
  const [captcha, setCaptcha] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => setIsDisabled(6 !== captcha.length), [captcha]);
  useEffect(() => {
    setCountDown(60);
  }, []);

  async function performResend() {
    if (0 < countDown) return;
    if (null == user) return;

    MessengerWrapper(props.messenger, async () => {
      await AuthAPI.resendCaptcha();
      setCountDown(60);
    });
  }

  async function performVerify() {
    MessengerWrapper(props.messenger, async () => {
      await AuthAPI.verifyCaptcha(user?.mobile || "", captcha);
      navigate("/login/trust");
    });
  }

  useEffect(() => {
    setTimeout(() => {
      if (countDown - 1 >= 0) setCountDown(countDown - 1);
    }, 1000);
  }, [countDown]);

  return (
    <LayoutLogin>
      <div className="legend compact">请输入手机验证码</div>
      <div className="description">
        * 验证码已发送至 +{user?.countryCode} {user?.mobile}，请查收。
        <br />* 如验证码接收或使用遇到问题请联系你的<b>客服</b>。
      </div>
      <div className="input-group">
        <div className="label">验证码</div>
        <PinInput
          length={6}
          type="numeric"
          inputMode="number"
          onChange={(value) => setCaptcha(value)}
          autoSelect={true}
          regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
        />
      </div>
      <div className="link-group">
        <Button type="link" className="link" onClick={performResend}>
          {countDown
            ? `重新发送${countDown ? `(${countDown})` : ""}`
            : "发送验证码"}
        </Button>
      </div>
      <div className="button-link-group">
        <div className="button-group">
          <Button
            className={"submit-button"}
            onClick={performVerify}
            disabled={isDisabled}
          >
            确认
          </Button>
        </div>
        <div className="link-group">
          <Link className="link" to={"/login"}>
            取消
          </Link>
        </div>
      </div>
    </LayoutLogin>
  );
}
