/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { Button, Dropdown, MenuProps, Modal, Table } from "antd";
import { useEffect, useState } from "react";
import { MessageInstance } from "antd/es/message/interface";
import Editor from "./Editor/Editor";
import ActionBar from "../../../../Layouts/LayoutHome/ActionBar/ActionBar";
import { MessengerWrapper } from "../../../../Common/MessengerWrapper";
import { HttpAPI } from "../../../../API/HttpAPI";
import { DateTagFamilyResultDto } from "../../../../API/Dto/date-tag-family-result.dto";
import { DateTagFamilyAPI } from "../../../../API/date-tag-family.api";

export default function TagFamily(props: { messenger: MessageInstance }) {
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(15);

  const [rows, setRows] = useState<DateTagFamilyResultDto[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);

  const [editingRow, setEditingRow] = useState<DateTagFamilyResultDto>();
  const [showEditor, setShowEditor] = useState<boolean>(false);

  useEffect(() => {
    refresh();
    HttpAPI.onOrganizationChange(refresh);
    return () => {
      HttpAPI.offOrganizationChange(refresh);
    };
  }, [page, pageSize]);

  async function refresh() {
    setShowEditor(false);

    MessengerWrapper(props.messenger, async () => {
      if (1 === page) {
        setRowCount(await DateTagFamilyAPI.count());
      }
      setRows(await DateTagFamilyAPI.page(page, pageSize));
    });
  }

  function create() {
    setEditingRow(undefined);
    setShowEditor(true);
  }

  async function edit(record: DateTagFamilyResultDto) {
    setEditingRow(record);
    setShowEditor(true);
  }

  function remove(record: DateTagFamilyResultDto) {
    Modal.confirm({
      title: "确认删除?",
      content: `此操作将不可恢复的删除 ${record.display}`,
      onOk: () => {
        MessengerWrapper(props.messenger, async () => {
          await DateTagFamilyAPI.removeById(record.id);
          refresh();
        });
      },
    });
  }

  return (
    <div>
      <ActionBar>
        <Button
          type="primary"
          className="add"
          onClick={() => create()}
        >
          {"添加标签族"}
        </Button>
      </ActionBar>
      <div className="table-title">所有标签族</div>
      <Table
        rowKey={"id"}
        dataSource={rows}
        columns={[
          { key: "display", title: "名称", dataIndex: "display" },
          {
            key: "key",
            title: "标识符",
            dataIndex: "key",
          },
          {
            key: "action",
            title: "操作",
            dataIndex: "id",
            width: 60,
            render: (value, record, index) => {
              const items: MenuProps['items'] = [
                {
                  key: 'edit',
                  label: "编辑",
                  onClick: () => edit(record),
                },
                {
                  key: 'delete',
                  label: "删除",
                  onClick: () => remove(record),
                },
              ];

              return <Dropdown menu={{ items }}>
                <a onClick={(e) => e.preventDefault()} className="table-action-more"></a>
              </Dropdown>;
            },
          },
        ]}
        pagination={{
          pageSize,
          total: rowCount,
          pageSizeOptions: [10, 15, 20, 50, 100],
          showSizeChanger: true,
          position: ["bottomRight"],
          onChange: (page, pageSize) => {
            setPage(page);
            setPageSize(pageSize);
          },
        }}
      ></Table>
      {showEditor && (
        <Editor
          messenger={props.messenger}
          editing={editingRow}
          onDone={() => refresh()}
        />
      )}
    </div>
  );
}
