/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { Button, DatePicker, Dropdown, Input, MenuProps, Modal, Table } from "antd";
import { useEffect, useState } from "react";
import { MessageInstance } from "antd/es/message/interface";
import Editor from "./Editor/Editor";
import ActionBar from "../../../../Layouts/LayoutHome/ActionBar/ActionBar";
import moment from "moment";
import { MessengerWrapper } from "../../../../Common/MessengerWrapper";
import { UserAPI } from "../../../../API/User.api";
import { HttpAPI } from "../../../../API/HttpAPI";
import { UserResultDto } from "../../../../API/Dto/user-result.dto";
import { CalendarOutlined, SearchOutlined } from "@ant-design/icons";
import { SecretaryRole } from "../../../../API/Enum/secretary-role";
import { AuthAPI } from "../../../../API/Auth.api";
import Importer from "./Importer/Importer";
import { OrganizationListResultDto } from "../../../../API/Dto/organization-list-result.dto";
import { OrganizationAPI } from "../../../../API/organization.api";
import './User.scss';

export default function User(props: { messenger: MessageInstance }) {
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(15);

  const [organizations, setOrganizations] = useState<OrganizationListResultDto[]>([]);
  const [currentOrganization, setCurrentOrganization] = useState<OrganizationListResultDto>();

  const [rows, setRows] = useState<UserResultDto[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);

  const [editingRow, setEditingRow] = useState<UserResultDto>();
  const [showEditor, setShowEditor] = useState<boolean>(false);
  const [showImporter, setShowImporter] = useState<boolean>(false);

  const user = AuthAPI.getLocalUser();

  const [searchCondition, setSearchCondition] = useState<Record<string, string | string[]>>({});

  useEffect(() => {
    HttpAPI.onOrganizationChange(refresh);
    refreshOrganization();
    return () => {
      HttpAPI.offOrganizationChange(refresh);
    };
  }, []);

  async function refreshOrganization() {
    const newOrgs = await OrganizationAPI.getRelated();
    setOrganizations(newOrgs);
    const currentOrgId = HttpAPI.getOrganization();
    const foundOrg = newOrgs.find(v => v.id === currentOrgId);
    setCurrentOrganization(foundOrg || newOrgs[0]);
    HttpAPI.setOrganization(foundOrg?.id || newOrgs[0].id);
  }

  function switchOrganization(org: OrganizationListResultDto) {
    setCurrentOrganization(org);
    HttpAPI.setOrganization(org.id);
  }

  useEffect(() => {
    refresh();
  }, [page, pageSize, searchCondition]);

  async function search(condition: Record<string, any[]>) {
    const newCondition: Record<string, string | string[]> = {};
    if (condition.name) newCondition.name = condition.name[0];
    if (condition.mobile) newCondition.mobile = condition.mobile[0];
    if (condition.gender) newCondition.gender = condition.gender[0];
    if (condition.createdAt) newCondition.createdAt = condition.createdAt;
    setSearchCondition({ ...newCondition });
  }

  async function refresh() {
    setShowEditor(false);
    setShowImporter(false);

    MessengerWrapper(props.messenger, async () => {
      if (1 === page) {
        setRowCount(await UserAPI.count(searchCondition));
      }
      setRows(await UserAPI.page(page, pageSize, searchCondition));
    });
  }

  function importExcel() {
    setShowImporter(true);
  }

  function create() {
    setEditingRow(undefined);
    setShowEditor(true);
  }

  async function edit(record: UserResultDto) {
    setEditingRow(await UserAPI.getById(record.id));
    setShowEditor(true);
  }

  function remove(record: UserResultDto) {
    Modal.confirm({
      title: "确认注销?",
      content: `此操作将不可恢复的注销 ${record.name}`,
      onOk: () => {
        MessengerWrapper(props.messenger, async () => {
          await UserAPI.removeById(record.id);
          refresh();
        });
      },
    });
  }

  return (
    <div>
      <div className="organization-legend">
        <div className="organization-info">
          <div className="name">{currentOrganization?.name}</div>
          <div className="switcher">
            <Dropdown menu={{ items: organizations.map(v => ({ key: v.id, label: v.name, onClick: () => switchOrganization(v) })) }}>
              <a onClick={(e) => e.preventDefault()} >切换公司</a>
            </Dropdown>
          </div>
        </div>

        <div className="personal-info">
          <div className="validity">公司服务有效期至：{moment(currentOrganization?.validUntil).format('YYYY年MM月DD日')}</div>
          <div className="avatar">{user?.name.substring(0, 1)}</div>
        </div>
      </div>
      <ActionBar>
        <Button
          type="primary"
          className="add"
          onClick={() => create()}
          hidden={user?.role !== SecretaryRole.BUTLER}
        >
          添加用户
        </Button>
        <Button
          type="primary"
          className="import"
          onClick={() => importExcel()}
          hidden={user?.role !== SecretaryRole.BUTLER}
        >
          批量上传
        </Button>
      </ActionBar>
      <div className="table-title">所有用户</div>
      <Table
        rowKey={"id"}
        dataSource={rows}
        onChange={(p, f: Record<string, any>) => p.current === page && search(f)}
        columns={[
          {
            key: "name",
            title: "姓名",
            dataIndex: "name",
            width: 180,
            filterDropdown: ({ selectedKeys, setSelectedKeys, confirm, clearFilters, close }) => (
              <div className="table-search">
                <Input
                  placeholder="搜索名称"
                  value={selectedKeys[0]}
                  onChange={(e) => setSelectedKeys((e.target.value ? [e.target.value] : []) as any)}
                />
                <Button
                  type="primary"
                  className="search-button"
                  onClick={() => confirm()}
                >
                  搜索
                </Button>
                <Button
                  type="default"
                  className="reset-button"
                  onClick={() => {
                    clearFilters!();
                  }}
                >
                  重置
                </Button>
                <Button
                  type="link"
                  className="close-button"
                  onClick={() => {
                    close();
                  }}
                >
                  关闭
                </Button>
              </div>
            ),
            filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />,
          },
          {
            key: "gender",
            title: "性别",
            dataIndex: "gender",
            width: 180,
            render: (value) => (({ 'MALE': '男', 'FEMALE': '女' } as any)[value]),
            filters: [
              {
                text: '男',
                value: 'MALE',
              },
              {
                text: '女',
                value: 'FEMALE',
              },
            ],
          },
          {
            key: "mobile",
            title: "手机号",
            dataIndex: "mobile",
            width: 180,
            filterDropdown: ({ selectedKeys, setSelectedKeys, confirm, clearFilters, close }) => (
              <div className="table-search">
                <Input
                  placeholder="搜索名称"
                  value={selectedKeys[0]}
                  onChange={(e) => setSelectedKeys((e.target.value ? [e.target.value] : []) as any)}
                />
                <Button
                  type="primary"
                  className="search-button"
                  onClick={() => confirm()}
                >
                  搜索
                </Button>
                <Button
                  type="default"
                  className="reset-button"
                  onClick={() => {
                    clearFilters!();
                  }}
                >
                  重置
                </Button>
                <Button
                  type="link"
                  className="close-button"
                  onClick={() => {
                    close();
                  }}
                >
                  关闭
                </Button>
              </div>
            ),
            filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />,
          },
          {
            key: "createdAt",
            title: "创建日期",
            dataIndex: "createdAt",
            render: (value) => moment(value).format("YYYY/MM/DD HH:mm:ss"),
            filterDropdown: ({ selectedKeys, setSelectedKeys, confirm, clearFilters, close }) => (
              <div className="table-search">
                <DatePicker.RangePicker
                  onChange={(e) => setSelectedKeys([e?.[0]?.format('YYYY-MM-DD') || '', e?.[1]?.format('YYYY-MM-DD') || ''])}

                />
                <Button
                  type="primary"
                  className="search-button"
                  onClick={() => confirm()}
                >
                  搜索
                </Button>
                <Button
                  type="default"
                  className="reset-button"
                  onClick={() => {
                    clearFilters!();
                  }}
                >
                  重置
                </Button>
                <Button
                  type="link"
                  className="close-button"
                  onClick={() => {
                    close();
                  }}
                >
                  关闭
                </Button>
              </div>
            ),
            filterIcon: (filtered: boolean) => <CalendarOutlined style={{ color: filtered ? '#1677ff' : undefined }} />,
          },
          {
            key: "action",
            title: "操作",
            dataIndex: "id",
            width: 60,
            render: (value, record, index) => {
              const items: MenuProps['items'] = [
                {
                  key: 'edit',
                  label: "编辑",
                  onClick: () => edit(record),
                },
                {
                  key: 'delete',
                  label: "注销",
                  onClick: () => remove(record),
                },
              ];

              return <Dropdown menu={{ items }}>
                <a onClick={(e) => e.preventDefault()} className="table-action-more"></a>
              </Dropdown>;
            },
          },
        ]}
        pagination={{
          pageSize,
          total: rowCount,
          pageSizeOptions: [10, 15, 20, 50, 100],
          showSizeChanger: true,
          position: ["bottomRight"],
          onChange: (page, pageSize) => {
            setPage(page);
            setPageSize(pageSize);
          },
        }}
      ></Table>
      {
        showEditor && (
          <Editor
            messenger={props.messenger}
            editing={editingRow}
            onDone={() => refresh()}
          />
        )
      }
      {
        showImporter && (
          <Importer
            messenger={props.messenger}
            onDone={() => refresh()}
          />
        )
      }
    </div >
  );
}
