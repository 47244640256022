/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { stringify } from "qs";
import { ArticleCreateDto } from "./Dto/article-create.dto";
import { ArticleListResultDto } from "./Dto/article-list-result.dto";
import { ArticleResultDto } from "./Dto/article-result.dto";
import { ArticleUpdateDto } from "./Dto/article-update.dto";
import { HttpAPI } from "./HttpAPI";

export class ArticleAPI {
  static getById(id: number): Promise<ArticleResultDto> {
    return HttpAPI.get(`/article/${id}`);
  }

  static count(condition: Record<string, string | string[]>): Promise<number> {
    return HttpAPI.get(`/article/count?${stringify(condition)}`);
  }

  static page(
    page: number,
    size: number,
    condition: Record<string, string | string[]>
  ): Promise<ArticleListResultDto[]> {
    return HttpAPI.get(`/article/${page}/${size}?${stringify(condition)}`);
  }

  static pageByCategory(
    categoryId: number,
    page: number,
    size: number
  ): Promise<ArticleListResultDto[]> {
    return HttpAPI.get(`/article/category/${categoryId}/${page}/${size}`);
  }

  static countByCategory(categoryId: number): Promise<number> {
    return HttpAPI.get(`/article/category/${categoryId}/count`);
  }

  static publish(id: number) {
    return HttpAPI.post(`/article/${id}/publish`);
  }

  static unpublish(id: number) {
    return HttpAPI.delete(`/article/${id}/publish`);
  }

  static create(data: ArticleCreateDto) {
    return HttpAPI.post(`/article`, data);
  }

  static updateById(id: number, data: ArticleUpdateDto) {
    return HttpAPI.patch(`/article/${id}`, data);
  }

  static removeById(id: number) {
    return HttpAPI.delete(`/article/${id}`);
  }
}
