/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { Button, Form, Transfer } from "antd";

import { MessageInstance } from "antd/es/message/interface";

import { OrganizationResultDto } from "../../../../../API/Dto/organization-result.dto";
import ModalEditor from "../../../../../Layouts/ModalEditor/ModalEditor";
import { SecretaryListResultDto } from "../../../../../API/Dto/secretary-list-result.dto";
import { useEffect, useState } from "react";
import { MessengerWrapper } from "../../../../../Common/MessengerWrapper";
import { SecretaryAPI } from "../../../../../API/secretary.api";
import { SecretaryRole } from "../../../../../API/Enum/secretary-role";
import { OrganizationRelationAPI } from "../../../../../API/organization-relation.api";

export default function Relation(props: {
  messenger: MessageInstance;
  editing: OrganizationResultDto;
  onDone: () => void;
}) {
  const [secretaries, setSecretaries] = useState<SecretaryListResultDto[]>([]);
  const [selectedSecretaries, setSelectedSecretaries] = useState<string[]>([]);

  const refresh = async () => {
    MessengerWrapper(props.messenger, async () => {
      setSecretaries(await SecretaryAPI.getByRole(SecretaryRole.BUTLER));
      setSelectedSecretaries(
        (
          await OrganizationRelationAPI.getSecretariesById(props.editing.id)
        ).map((v) => v.secretary.id.toString())
      );
    });
  };

  useEffect(() => {
    refresh();
  }, []);

  return (
    <ModalEditor
      messenger={props.messenger}
      editing={props.editing}
      name="关联"
      title="关联幸福大使"
      onDone={props.onDone}
      onSubmit={async (d: any) => {
        await OrganizationRelationAPI.updateById(
          props.editing.id,
          d.secretaries.map((v: string) => +v)
        );
      }}
    >
      <Form.Item
        name="secretaries"
        rules={[{ required: false, message: "请选择隶属于组织的幸福大使" }]}
      >
        <Transfer
          dataSource={secretaries}
          showSearch
          targetKeys={selectedSecretaries}
          rowKey={(v) => v.id.toString()}
          onChange={(d) => setSelectedSecretaries(d)}
          render={(item) => `${item.name} +${item.countryCode} ${item.mobile}`}
          listStyle={{ flex: 1 }}
        />
      </Form.Item>
      <Form.Item className="save-button">
        <Button type="primary" htmlType="submit">
          确认修改
        </Button>
      </Form.Item>
    </ModalEditor>
  );
}
