/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { Button, Form, Input } from "antd";

import { MessageInstance } from "antd/es/message/interface";

import "./Editor.scss";
import ModalEditor from "../../../../../Layouts/ModalEditor/ModalEditor";
import { DateTagFamilyCreateDto } from "../../../../../API/Dto/date-tag-family-create.dto";
import { DateTagFamilyAPI } from "../../../../../API/date-tag-family.api";
import { DateTagFamilyResultDto } from "../../../../../API/Dto/date-tag-family-result.dto";

export default function Editor(props: {
  messenger: MessageInstance;
  editing?: DateTagFamilyResultDto;
  onDone: () => void;
}) {

  function done() {
    props.onDone();
  }


  return (
    <ModalEditor
      messenger={props.messenger}
      editing={props.editing}
      name="标签族"
      onDone={done}
      onSubmit={async (d) => {
        const formData: DateTagFamilyCreateDto = {
          display: d.display,
          key: d.key,
        };

        if (props.editing) {
          await DateTagFamilyAPI.updateById(props.editing.id, formData);
        } else {
          await DateTagFamilyAPI.create(formData);
        }
      }}
    >
      <Form.Item
        label="名称"
        name="display"
        rules={[{ required: true, message: "请输入名称" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="标识符"
        name="key"
        rules={[{ required: true, message: "请输入标识符" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item className="save-button">
        <Button type="primary" htmlType="submit">
          {props.editing ? "确认修改" : "确认创建"}
        </Button>
      </Form.Item>
    </ModalEditor>
  );
}
