/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { Button, Form, Input, InputNumber, Switch } from "antd";

import { MessageInstance } from "antd/es/message/interface";

import ModalEditor from "../../../../../Layouts/ModalEditor/ModalEditor";
import { VideoTagAPI } from "../../../../../API/video-tag.api";
import { VideoTagCreateDto } from "../../../../../API/Dto/video-tag-create.dto";
import { VideoTagResultDto } from "../../../../../API/Dto/video-tag-result.dto";
import { useEffect, useState } from "react";

export default function Editor(props: {
  messenger: MessageInstance;
  editing?: VideoTagResultDto;
  onDone: () => void;
}) {
  const [isHidden, setIsHidden] = useState(false);

  useEffect(() => {
    setIsHidden(props.editing?.hidden || false);
  }, [props.editing]);

  return (
    <ModalEditor
      messenger={props.messenger}
      editing={props.editing}
      name={"视频标签"}
      onDone={props.onDone}
      onSubmit={async (d: any) => {
        const formData: VideoTagCreateDto = {
          display: d.display,
          hidden: isHidden,
          sort: d.sort,
        };
        await (props.editing
          ? VideoTagAPI.updateById(props.editing.id, formData)
          : VideoTagAPI.create(formData));
      }}
    >
      <div className="form-row">
        <div className="form-row-expanded">
          <Form.Item
            label="名称"
            name="display"
            rules={[{ required: true, message: "请输入名称" }]}
          >
            <Input />
          </Form.Item>
        </div>
        <Form.Item
          label="排序"
          name="sort"
          rules={[{ required: true, message: "" }]}
          initialValue={0}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item label="隐藏" name="openBlank">
          <Switch
            checked={isHidden || false}
            onChange={(d) => setIsHidden(d)}
          />
        </Form.Item>
      </div>
      <Form.Item className="save-button">
        <Button type="primary" htmlType="submit">
          {props.editing ? "确认修改" : "确认创建"}
        </Button>
      </Form.Item>
    </ModalEditor>
  );
}
