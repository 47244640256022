/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { DateTagOptionCreateDto } from "./Dto/date-tag-option-create.dto";
import { DateTagOptionUpdateDto } from "./Dto/date-tag-option-update.dto";
import { HttpAPI } from "./HttpAPI";

export class DateTagOptionAPI {

  static pageByFamily(family:number, page: number, size: number) {
    return HttpAPI.get(`/date/tag/option/${family}/${page}/${size}`);
  }
  static countByFamily(family:number) {
    return HttpAPI.get(`/date/tag/option/${family}/count`);
  }

  static removeById(id: number) {
    return HttpAPI.delete(`/date/tag/option/${id}`);
  }

  static create(data: DateTagOptionCreateDto) {
    return HttpAPI.post("/date/tag/option", data);
  }

  static updateById(id: number, data: DateTagOptionUpdateDto) {
    return HttpAPI.patch(`/date/tag/option/${id}`, data);
  }

}
