/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { stringify } from "qs";
import { SecretaryCreateDto } from "./Dto/secretary-create.dto";
import { SecretaryListResultDto } from "./Dto/secretary-list-result.dto";
import { SecretaryResultDto } from "./Dto/secretary-result.dto";
import { SecretaryUpdateDto } from "./Dto/secretary-update.dto";
import { HttpAPI } from "./HttpAPI";

export class SecretaryAPI {
  static getByRole(role: string): Promise<SecretaryListResultDto[]> {
    return HttpAPI.get(`/secretary/role/${role}`);
  }

  static getById(id: number): Promise<SecretaryResultDto> {
    return HttpAPI.get(`/secretary/id/${id}`);
  }

  static page(
    type: string,
    page: number,
    pageSize: number,
    condition: Record<string, string | string[]>
  ): Promise<SecretaryListResultDto[]> {
    return HttpAPI.get(
      `/secretary/${type}/${page}/${pageSize}?${stringify(condition)}`
    );
  }

  static count(type: string, condition: Record<string, string | string[]>) {
    return HttpAPI.get(`/secretary/${type}/count?${stringify(condition)}`);
  }

  static create(data: SecretaryCreateDto): Promise<SecretaryResultDto> {
    return HttpAPI.post(`/secretary`, data);
  }

  static updateById(id: number, data: SecretaryUpdateDto) {
    return HttpAPI.patch(`/secretary/id/${id}`, data);
  }

  static recoverPasswordById(id: number, password: string) {
    return HttpAPI.patch(`/secretary/id/${id}/recover`, { password });
  }

  static removeById(id: number) {
    return HttpAPI.delete(`/secretary/id/${id}`);
  }
}
