/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { Button, Form, Input, InputNumber } from "antd";

import { MessageInstance } from "antd/es/message/interface";

import ModalEditor from "../../../../../Layouts/ModalEditor/ModalEditor";
import { VersionCreateDto } from "../../../../../API/Dto/version-create.dto";
import { VersionAPI } from "../../../../../API/version.api";

export default function Editor(props: {
  messenger: MessageInstance;
  onDone: () => void;
}) {
  return (
    <ModalEditor
      messenger={props.messenger}
      editing={undefined}
      name={"版本"}
      onDone={props.onDone}
      onSubmit={async (d: VersionCreateDto) => {
        await VersionAPI.create(d);
        props.onDone();
      }}
    >
      <div className="form-row">
        <div className="form-row-expanded">
          <Form.Item
            label="显示版本"
            name="display"
            rules={[{ required: true, message: "请输入显示版本" }]}
          >
            <Input />
          </Form.Item>
        </div>
        <Form.Item
          label="编译号"
          name="build"
          rules={[{ required: true, message: "请输入编译号" }]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          label="最小编译"
          name="minimalBuild"
          rules={[{ required: true, message: "请输入最小编译号" }]}
          initialValue={0}
        >
          <InputNumber />
        </Form.Item>
      </div>
      <Form.Item
        label="iOS 链接"
        name="iosUrl"
        rules={[{ required: true, message: "请输入 iOS 链接" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="安卓链接"
        name="androidUrl"
        rules={[{ required: true, message: "请输入 Android 链接" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item className="save-button">
        <Button type="primary" htmlType="submit">
          确认创建
        </Button>
      </Form.Item>
    </ModalEditor>
  );
}
