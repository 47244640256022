/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { Button, Form, Input } from "antd";

import { MessageInstance } from "antd/es/message/interface";

import ModalEditor from "../../../../../Layouts/ModalEditor/ModalEditor";
import { SecretaryResultDto } from "../../../../../API/Dto/secretary-result.dto";
import { SecretaryAPI } from "../../../../../API/secretary.api";

export default function RecoverPassword(props: {
  messenger: MessageInstance;
  editing: SecretaryResultDto;
  onDone: () => void;
}) {
  return (
    <ModalEditor
      messenger={props.messenger}
      editing={
        props.editing
          ? {
              ...props.editing,
              organizationIds: props.editing.organizations.map(
                (v) => v.organizationId
              ),
            }
          : undefined
      }
      name={"重置密码"}
      title="重置密码"
      onDone={props.onDone}
      onSubmit={async (d: any) => {
        await SecretaryAPI.recoverPasswordById(props.editing.id, d.password);
      }}
    >
      <Form.Item
        label="新密码"
        name="password"
        rules={[{ required: true, message: "请输入新密码" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item className="save-button">
        <Button type="primary" htmlType="submit">
          确认重置
        </Button>
      </Form.Item>
    </ModalEditor>
  );
}
