/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { stringify } from "qs";
import { UserCreateDto } from "./Dto/user-create.dto";
import { UserUpdateDto } from "./Dto/user-update.dto";
import { HttpAPI } from "./HttpAPI";

export class UserAPI {
  static get() {
    return HttpAPI.get("/user");
  }

  static searchByMobile(mobile: string) {
    return HttpAPI.get(`/user/mobile/${mobile}`);
  }

  static page(
    page: number,
    size: number,
    condition: Record<string, string | string[]>
  ) {
    return HttpAPI.get(`/user/${page}/${size}?${stringify(condition)}`);
  }

  static count(condition: Record<string, string | string[]>) {
    return HttpAPI.get(`/user/count?${stringify(condition)}`);
  }

  static pageByOrganization(
    orgId: number,
    page: number,
    size: number,
    condition: Record<string, string | string[]>
  ) {
    return HttpAPI.get(`/user/${page}/${size}?${stringify(condition)}`);
  }

  static countByOrganization(
    orgId: number,
    condition: Record<string, string | string[]>
  ) {
    return HttpAPI.get(`/user/count?${stringify(condition)}`);
  }

  static getById(id: number) {
    return HttpAPI.get(`/user/id/${id}`);
  }
  static removeById(id: number) {
    return HttpAPI.delete(`/user/${id}`);
  }

  static create(data: UserCreateDto) {
    return HttpAPI.post("/user", data);
  }

  static updateById(id: number, data: UserUpdateDto) {
    return HttpAPI.patch(`/user/${id}`, data);
  }

  static async importExcel(organizationId: number, file: File) {
    const formData = new FormData();
    formData.append("file", file, encodeURI(file.name));
    return HttpAPI.post(`/user/import/${organizationId}`, formData);
  }
}
