/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { Button } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { MessageInstance } from "antd/es/message/interface";
import { AuthAPI } from "../../../API/Auth.api";
import LayoutLogin from "../../../Layouts/LayoutLogin/LayoutLogin";

export default function Trust(props: { messenger: MessageInstance }) {
  const navigate = useNavigate();

  async function performTrust() {
    try {
      const loggedInUser = await AuthAPI.getLocalUser();
      localStorage[`${loggedInUser?.id}-trusted`] = 1;
      navigate(loggedInUser?.isInitialized ? "/home" : "/login/initial");
    } catch (e) {
      props.messenger.open({
        className: "global-error-toast",
        content: (e as Error).message,
        duration: 1,
      });
    }
  }

  async function performLater() {
    try {
      const loggedInUser = await AuthAPI.getLocalUser();
      navigate(loggedInUser?.isInitialized ? "/home" : "/login/initial");
    } catch (e) {
      props.messenger.open({
        className: "global-error-toast",
        content: (e as Error).message,
        duration: 1,
      });
    }
  }

  return (
    <LayoutLogin>
      <div className="legend compact">信任浏览器</div>
      <div className="description">
        信任本浏览器后下次登录时不再需要使用验证码。
      </div>
      <div className="button-link-group">
        <div className="button-group">
          <Button className={"submit-button"} onClick={performTrust}>
            信任
          </Button>
        </div>
      </div>
      <div className="button-link-group">
        <div className="button-group">
          <Link className="link" onClick={performLater} to={"#"}>
            以后再说
          </Link>
        </div>
      </div>
    </LayoutLogin>
  );
}
