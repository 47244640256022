/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { MessageInstance } from "antd/es/message/interface";
import { useEffect, useRef, useState } from "react";
import { ConsultAPI } from "../../../../../API/consult.api";

import "./Detail.scss";
import { ConsultTopicResultDto } from "../../../../../API/Dto/consult-topic-result.dto";
import moment from "moment";
import { Link, useSearchParams } from "react-router-dom";
import { Button, Form, FormInstance, Modal } from "antd";
import TextArea from "antd/es/input/TextArea";
import { AuthAPI } from "../../../../../API/Auth.api";
import { MessengerWrapper } from "../../../../../Common/MessengerWrapper";
import { ConsultPostResultDto } from "../../../../../API/Dto/consult-post-result.dto";

export default function Detail(props: {
  messenger: MessageInstance;
}) {
  const [topic, setTopic] = useState<ConsultTopicResultDto>();
  const user = AuthAPI.getLocalUser();

  useEffect(() => {
    refresh();
  }, []);

  const [searchParams] = useSearchParams();
  const formRef = useRef<FormInstance>(null);
  const topicId = +(searchParams.get('id') || 0);

  async function refresh() {
    const topics = await ConsultAPI.getTopicById(topicId);
    setTopic(topics);
    formRef.current?.resetFields();
  }

  const [editingRows, setEditingRows] = useState<number[]>([]);
  const editFormRefs = useRef<(FormInstance | null)[]>([]);

  async function edit(post: ConsultPostResultDto) {
    setEditingRows([...editingRows, post.id]);
  }
  async function cancelEdit(post: ConsultPostResultDto, formIndex: number) {
    setEditingRows(editingRows.filter(e => e !== post.id));
    editFormRefs.current[formIndex]?.setFieldsValue(post);
  }
  async function saveEdit(post: ConsultPostResultDto, data: any) {
    MessengerWrapper(props.messenger, async () => {
      await ConsultAPI.updatePostById(
        topicId,
        post.id,
        data.content
      );
      setEditingRows(editingRows.filter(e => e !== post.id));
    })
  }

  async function remove(id: number) {
    Modal.confirm({
      title: "确认删除?",
      onOk: () => {
        MessengerWrapper(props.messenger, async () => {
          await ConsultAPI.deletePostById(topicId, id);
          refresh();
        });
      },
    });
  }

  async function post(d: any) {
    if (!d.content?.trim()) {
      props.messenger.error('请输入回复内容');
      return;
    }
    MessengerWrapper(props.messenger, async () => {
      await ConsultAPI.createPost(topicId, d.content);
      refresh();
    })
  }

  return (
    <>
      <div className="topic-viewer">
        <div className="topic-title">
          <div className="topic-main">
            <Link className="sub" to={`#`} onClick={() => history.back()}>提问列表</Link>
            <span className="separator">/</span>
            <span className="title">{topic?.title}</span>
          </div>
        </div>
        <div className="topic-main">
          <div className="title">{topic?.title}</div>
          <div className="content">{topic?.content}</div>
          <div className="created-time">
            发布于{moment(topic?.createdAt).format("YYYY年MM月DD日")}
          </div>
          {topic?.consultPosts.map((e, i) => (
            <Form
              className="post"
              ref={(e) => editFormRefs.current[i] = e}
              onFinish={(d) => saveEdit(e, d)}
            >
              <div className="post-user">
                <div className={`avatar ${e.secretary ? '' : 'anonymous'}`}>
                  <img src={e.secretary?.avatarUrl} alt="" />
                </div>
                <div className="text">
                  <div className="name">
                    {e.secretary ? e.secretary?.name : "匿名用户"}
                  </div>
                  <div className="qualification">
                    {e.secretary ? e.secretary?.qualification : ""}
                  </div>
                </div>
                <div className="expanded"></div>
                <div className="posted-time">
                  {moment(e.createdAt).format("MM月DD日")}
                </div>
              </div>
              <Form.Item name='content'>
                <TextArea className="content" readOnly={!editingRows.includes(e.id)} autoSize defaultValue={e.content} bordered={false} />
              </Form.Item>
              <div
                className="actions"
                hidden={e.secretary?.id !== user?.id || editingRows.includes(e.id)}
              >
                <Button
                  type="text"
                  className="edit"
                  onClick={() => edit(e)}
                >
                  编辑
                </Button>
                <Button
                  type="text"
                  className="remove"
                  onClick={() => remove(e.id)}
                >
                  删除
                </Button>
              </div>
              <div
                className="actions"
                hidden={!editingRows.includes(e.id)}
              >
                <Button className="cancel" type="ghost" onClick={() => cancelEdit(e, i)}>取消</Button>
                <Button className="save" type="ghost" htmlType="submit">保存</Button>
              </div>
            </Form>
          ))}
          <Form className="post-editor" onFinish={(d) => post(d)} ref={formRef}>
            <div className="editor">
              <div className={`avatar`}>
                <img src={user?.avatarUrl} alt="" />
              </div>
              <Form.Item name="content" className="expanded">
                <TextArea rows={4} placeholder="请输入..." bordered={false} />
              </Form.Item>
            </div>
            <Button className="add" type="ghost" htmlType="submit">发布回复</Button>
          </Form>
        </div>
      </div>
    </>
  );
}
