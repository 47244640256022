/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { Button, Form, Modal } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { MessageInstance } from "antd/es/message/interface";
import ModalEditor from "../../../../../Layouts/ModalEditor/ModalEditor";
import Dragger from "antd/es/upload/Dragger";
import { UserAPI } from "../../../../../API/User.api";
import { HttpAPI } from "../../../../../API/HttpAPI";

export default function Importer(props: {
  messenger: MessageInstance;
  onDone: () => void;
}) {


  return (
    <ModalEditor
      messenger={props.messenger}
      name={"用户"}
      title={'从 Excel 导入用户'}
      onDone={props.onDone}
      onBeforeSubmit={async (d) => {
        return new Promise((r, e) => {
          Modal.confirm({
            title: "确认创建",
            content: '即将创建用户',
            onOk: async () => {
              r(true);
            },
            onCancel: async () => {
              r(false);
            },
          });
        });
      }}
      onSubmit={async (d: any) => {
        let totalImported = 0;
        for (const file of d.file.fileList) {
          totalImported += await UserAPI.importExcel(HttpAPI.getOrganization(), file.originFileObj);
        }
        Modal.success({
          title: "导入完成",
          content: `共导入 ${totalImported} 个用户`,
        });
      }}
    >
      <Form.Item
        label=""
        name="file"
        rules={[{ required: true, message: "请上传文件" }]}
      >
        <Dragger
          onChange={(v) => console.log(v)}
          beforeUpload={(v) => false}
          accept=".xls,.xlsx"
          showUploadList={true}
          multiple={false}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">点击选择或拖拽文件</p>
          <p className="ant-upload-hint">支持 xls, xlsx 格式</p>
        </Dragger>
      </Form.Item>
      <Form.Item className="save-button">
        <a href="/用户导入模版.xlsx" download="用户导入模版.xlsx" target='_blank'>
          <Button>Excel模版下载</Button>
        </a>
        <Button type="primary" htmlType="submit">
          确认导入
        </Button>
      </Form.Item>
    </ModalEditor>
  );
}
