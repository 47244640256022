/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { Button, Form, Input, Select } from "antd";

import { MessageInstance } from "antd/es/message/interface";

import ModalEditor from "../../../../../Layouts/ModalEditor/ModalEditor";
import { SecretaryResultDto } from "../../../../../API/Dto/secretary-result.dto";
import { SecretaryAPI } from "../../../../../API/secretary.api";
import { SecretaryCreateDto } from "../../../../../API/Dto/secretary-create.dto";
import { useEffect, useState } from "react";
import { OrganizationAPI } from "../../../../../API/organization.api";
import { OrganizationListResultDto } from "../../../../../API/Dto/organization-list-result.dto";
import { SecretaryRole } from "../../../../../API/Enum/secretary-role";

export default function Editor(props: {
  messenger: MessageInstance;
  editing?: SecretaryResultDto;
  onDone: () => void;
}) {
  const [organizations, setOrganizations] = useState<
    OrganizationListResultDto[]
  >([]);
  useEffect(() => {
    _refresh();
  }, []);

  async function _refresh() {
    setOrganizations(await OrganizationAPI.get());
  }
  return (
    <ModalEditor
      messenger={props.messenger}
      editing={
        props.editing
          ? {
            ...props.editing,
            organizationIds: props.editing.organizations.map(
              (v) => v.organizationId
            ),
          }
          : undefined
      }
      name={"大使"}
      onValueChange={(k, v) => {
        if (props.editing) {
          Object.assign(props.editing, k);
        }
      }}
      onDone={props.onDone}
      onSubmit={async (d: any) => {
        const formData: SecretaryCreateDto = {
          name: d.name,
          countryCode: "86",
          mobile: d.mobile,
          role: SecretaryRole.BUTLER,
          password: d.password,
          organizationIds: d.organizationIds,
          consultantRole: d.consultantRole,
        };
        await (props.editing
          ? SecretaryAPI.updateById(props.editing.id, formData)
          : SecretaryAPI.create(formData));
      }}
    >
      <Form.Item
        label="名称"
        name="name"
        rules={[{ required: true, message: "请输入名称" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="手机号"
        name="mobile"
        rules={[{ required: true, message: "请输入手机号" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="初始密码"
        name="password"
        rules={[{ required: !props.editing, message: "请输入初始密码" }]}
        hidden={!!props.editing}
      >
        <Input />
      </Form.Item>
      {/* <Form.Item
        label="角色"
        name="role"
        rules={[{ required: true, message: "请选择角色" }]}
      >
        <Select className="role">
          <Select.Option value={SecretaryRole.BUTLER}>幸福大使</Select.Option>
          <Select.Option value={SecretaryRole.CUSTOMER_SERVICE}>
            客服
          </Select.Option>
          <Select.Option value={SecretaryRole.CONSULTANT}>咨询师</Select.Option>
        </Select>
      </Form.Item> */}
      <Form.Item
        label="组织"
        name="organizationIds"
        rules={[
        ]}
      >
        <Select
          className="organizationIds"
          mode="multiple"
          options={organizations.map((v) => ({ label: v.name, value: v.id }))}
        ></Select>
      </Form.Item>
      {/* <Form.Item
        label="咨询类型"
        name="consultantRole"
        rules={[
          {
            required: SecretaryRole.CONSULTANT === role,
            message: "请选择咨询类型",
          },
        ]}
        hidden={SecretaryRole.CONSULTANT !== role}
      >
        <Select mode="multiple" className="consultant">
          <Select.Option value={ConsultantTag.LEGAL}>法律</Select.Option>
          <Select.Option value={ConsultantTag.PSYCHOLOGICAL}>
            心理
          </Select.Option>
        </Select>
      </Form.Item> */}
      <Form.Item className="save-button">
        <Button type="primary" htmlType="submit">
          {props.editing ? "确认修改" : "确认创建"}
        </Button>
      </Form.Item>
    </ModalEditor>
  );
}
