/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { Button, Modal, Table } from "antd";
import { useEffect, useState } from "react";
import { MessageInstance } from "antd/es/message/interface";
import Editor from "./Editor/Editor";
import ActionBar from "../../../../Layouts/LayoutHome/ActionBar/ActionBar";
import { MessengerWrapper } from "../../../../Common/MessengerWrapper";
import { VersionListResultDto } from "../../../../API/Dto/version-list-result.dto";
import { VersionAPI } from "../../../../API/version.api";
import moment from "moment";

export default function Version(props: { messenger: MessageInstance }) {
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(15);

  const [rows, setRows] = useState<VersionListResultDto[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);

  const [showEditor, setShowEditor] = useState<boolean>(false);

  useEffect(() => {
    refresh();
  }, [page, pageSize]);

  async function refresh() {
    setShowEditor(false);

    MessengerWrapper(props.messenger, async () => {
      if (1 === page) {
        setRowCount(await VersionAPI.count());
      }
      setRows(await VersionAPI.page(page, pageSize));
    });
  }

  function create() {
    setShowEditor(true);
  }

  async function publishIOS(record: VersionListResultDto) {
    Modal.confirm({
      title: "确认发布 iOS?",
      content: `此操作不可恢复`,
      onOk: () => {
        MessengerWrapper(props.messenger, async () => {
          await VersionAPI.publishIOSById(record.id);
          refresh();
        });
      },
    });
  }

  async function publishAndroid(record: VersionListResultDto) {
    Modal.confirm({
      title: "确认发布 Android?",
      content: `此操作不可恢复`,
      onOk: () => {
        MessengerWrapper(props.messenger, async () => {
          await VersionAPI.publishAndroidById(record.id);
          refresh();
        });
      },
    });
  }

  function remove(record: VersionListResultDto) {
    Modal.confirm({
      title: "确认删除?",
      content: `此操作将不可恢复的删除版本 ${record.display}`,
      onOk: () => {
        MessengerWrapper(props.messenger, async () => {
          await VersionAPI.removeById(record.id);
          refresh();
        });
      },
    });
  }

  return (
    <div>
      <ActionBar>
        <Button type="primary" className="add" onClick={() => create()}>
          {"添加版本"}
        </Button>
      </ActionBar>
      <Table
        rowKey={"id"}
        dataSource={rows}
        columns={[
          { key: "display", title: "版本号", dataIndex: "display" },
          { key: "build", title: "编译号", dataIndex: "build", width: 100 },
          {
            key: "minimalBuild",
            title: "最小编译号",
            dataIndex: "minimalBuild",
            width: 120,
          },
          {
            key: "iosAvailable",
            title: "发布 iOS",
            dataIndex: "iosAvailable",
            width: 180,
            render: (value, record, index) => (value ? "已发布" : "未发布"),
          },
          {
            key: "androidAvailable",
            title: "发布 Android",
            dataIndex: "androidAvailable",
            width: 180,
            render: (value, record, index) => (value ? "已发布" : "未发布"),
          },
          {
            key: "publishedAt",
            title: "发布于",
            dataIndex: "publishedAt",
            width: 200,
            render: (value) => moment(value).format("YYYY-MM-DD HH:mm:ss"),
          },
          {
            key: "action",
            title: "操作",
            dataIndex: "id",
            width: 320,
            render: (value, record, index) => (
              <>
                <Button
                  type="link"
                  onClick={() => publishIOS(record)}
                  hidden={record.iosAvailable}
                >
                  发布 iOS
                </Button>
                <Button
                  type="link"
                  onClick={() => publishAndroid(record)}
                  hidden={record.androidAvailable}
                >
                  发布 Android
                </Button>
                <Button type="link" onClick={() => remove(record)}>
                  删除
                </Button>
              </>
            ),
          },
        ]}
        pagination={{
          pageSize,
          total: rowCount,
          pageSizeOptions: [10, 15, 20, 50, 100],
          showSizeChanger: true,
          position: ["bottomRight"],
          onChange: (page, pageSize) => {
            setPage(page);
            setPageSize(pageSize);
          },
        }}
      ></Table>
      {showEditor && (
        <Editor messenger={props.messenger} onDone={() => refresh()} />
      )}
    </div>
  );
}
