/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { Button, Form, Input, InputNumber, Select, Upload } from "antd";

import { MessageInstance } from "antd/es/message/interface";

import ModalEditor from "../../../../../Layouts/ModalEditor/ModalEditor";
import { VideoResultDto } from "../../../../../API/Dto/video-result.dto";
import { VideoCreateDto } from "../../../../../API/Dto/video-create.dto";
import { VideoAPI } from "../../../../../API/video.api";
import TextArea from "antd/es/input/TextArea";
import { PlusOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { RcFile } from "antd/es/upload";
import { OSSApi } from "../../../../../API/oss.api";
import { VideoTagListResultDto } from "../../../../../API/Dto/video-tag-list-result.dto";
import { VideoTagAPI } from "../../../../../API/video-tag.api";

export default function Editor(props: {
  messenger: MessageInstance;
  editing?: VideoResultDto;
  onDone: () => void;
}) {
  const [coverFile, setCoverFile] = useState<RcFile | null>(null);
  const [videoFile, setVideoFile] = useState<RcFile | null>(null);
  const [tags, setTags] = useState<VideoTagListResultDto[]>([]);

  const [editing, setEditing] = useState<
    (Omit<VideoResultDto, "categories"> & { tagIds: number[] }) | null
  >(null);

  async function preventUpload(setFunc: (f: RcFile) => void, file: RcFile) {
    setFunc(file);
    return false;
  }
  useEffect(() => {
    _refresh();
  }, []);

  useEffect(() => {
    if (props.editing) {
      setEditing({
        ...props.editing,
        tagIds: props.editing.tags.map((v) => v.id),
      });
    }
  }, [props.editing]);

  async function _refresh() {
    setTags(await VideoTagAPI.get());
  }

  return (
    <ModalEditor
      messenger={props.messenger}
      editing={editing}
      name={"视频"}
      onDone={props.onDone}
      onSubmit={async (d: any) => {
        const formData: VideoCreateDto = {
          title: d.title,
          description: d.description,
          sort: d.sort,
          tagIds: d.tagIds,
          coverUrl:
            (coverFile ? await OSSApi.upload(coverFile) : null) ||
            props.editing?.coverUrl ||
            "",
          videoUrl:
            (videoFile ? await OSSApi.upload(videoFile) : null) ||
            props.editing?.videoUrl ||
            "",
        };
        await (props.editing
          ? VideoAPI.updateById(props.editing.id, formData)
          : VideoAPI.create(formData));
      }}
    >
      <Form.Item
        label="标题"
        name="title"
        rules={[{ required: true, message: "请输入标题" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="标签"
        name="tagIds"
        rules={[{ required: true, message: "至少选择一个标签" }]}
      >
        <Select
          showSearch
          className="tagIds"
          allowClear
          mode="multiple"
          options={tags.map((v) => ({ label: v.display, value: v.id }))}
        />
      </Form.Item>
      <div className="form-row">
        <div className="form-row-expanded">
          <Form.Item
            label="描述"
            name="description"
            rules={[{ required: true, message: "请输入描述" }]}
          >
            <TextArea />
          </Form.Item>
        </div>
        <Form.Item
          label="排序"
          name="sort"
          rules={[{ required: true, message: "请输入排序" }]}
          initialValue={0}
        >
          <InputNumber />
        </Form.Item>
      </div>
      <div className="form-row">
        <Form.Item
          label="封面图"
          name="coverFile"
          rules={[{ required: !props.editing, message: "请选择封面图" }]}
        >
          <Upload
            accept=".jpg, .png, .jpeg"
            listType="picture-card"
            className="album-uploader"
            showUploadList={false}
            beforeUpload={(f) => preventUpload(setCoverFile, f)}
          >
            <div className="previewer">
              {coverFile || props.editing ? (
                <img
                  src={
                    coverFile
                      ? window.URL.createObjectURL(new Blob([coverFile]))
                      : props.editing?.coverUrl
                  }
                />
              ) : (
                <div>
                  <div style={{ marginTop: 8 }}>
                    <PlusOutlined /> 请上传封面图
                  </div>
                </div>
              )}
            </div>
          </Upload>
        </Form.Item>
        <Form.Item
          label="视频"
          name="videoFile"
          rules={[{ required: !props.editing, message: "请选择视频" }]}
        >
          <Upload
            accept=".mp4"
            listType="picture-card"
            className="album-uploader"
            showUploadList={false}
            beforeUpload={(f) => preventUpload(setVideoFile, f)}
          >
            <div className="previewer">
              {videoFile || props.editing ? (
                <video
                  src={
                    videoFile
                      ? window.URL.createObjectURL(new Blob([videoFile]))
                      : props.editing?.videoUrl
                  }
                />
              ) : (
                <div>
                  <div style={{ marginTop: 8 }}>
                    <PlusOutlined /> 请上传视频
                  </div>
                </div>
              )}
            </div>
          </Upload>
        </Form.Item>
      </div>
      <Form.Item className="save-button">
        <Button type="primary" htmlType="submit">
          {props.editing ? "确认修改" : "确认创建"}
        </Button>
      </Form.Item>
    </ModalEditor>
  );
}
