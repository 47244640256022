/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { OrganizationRelationResultDto } from "./Dto/organization-relation-result.dto";
import { HttpAPI } from "./HttpAPI";

export class OrganizationRelationAPI {
  static getSecretariesById(
    id: number
  ): Promise<OrganizationRelationResultDto[]> {
    return HttpAPI.get(`/organization-relation/${id}`);
  }

  static updateById(id: number, secretaries: number[]) {
    return HttpAPI.patch(`/organization-relation/${id}`, { secretaries });
  }
}
