/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { Button, Form, FormInstance, Input } from "antd";
import { useRef } from "react";
import { AuthAPI } from "../../../../../API/Auth.api";
import { MessageInstance } from "antd/es/message/interface";

import './Password.scss';
import { MessengerWrapper } from "../../../../../Common/MessengerWrapper";
import ModalEditor from "../../../../../Layouts/ModalEditor/ModalEditor";

export default function Password(props: { messenger: MessageInstance; onDone: () => void }) {

  const formRef = useRef<FormInstance>(null)

  async function perform(data: any) {
    if (data.confirmPassword !== data.newPassword) {
      props.messenger.error('两次密码不一致');
      return;
    }
    MessengerWrapper(props.messenger, async () => {
      await AuthAPI.changePassword(data.originPassword, data.newPassword);
      props.messenger.success('密码已修改');
      formRef.current?.resetFields();
    });
  }

  return <ModalEditor
    messenger={props.messenger}
    width={410}
    name={""}
    title="修改密码"
    onDone={props.onDone}
    onSubmit={function (d: any): void | Promise<void> {
      perform(d);
    }}>
    <Form.Item label="原密码" name="originPassword" required>
      <Input type="password" />
    </Form.Item>
    <div className="password-hint">如忘记原密码，请使用验证码登陆，即可重置密码。</div>
    <Form.Item label="新密码" name="newPassword" required>
      <Input type="password" />
    </Form.Item>
    <Form.Item label="确认新密码" name='confirmPassword' required>
      <Input type="password" />
    </Form.Item>
    <Form.Item className="save-button">
      <Button type="primary" htmlType="submit">
        {"确认修改"}
      </Button>
    </Form.Item>
  </ModalEditor>;
}