/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { Button, DatePicker, Form, Input } from "antd";

import { MessageInstance } from "antd/es/message/interface";

import { OrganizationResultDto } from "../../../../../API/Dto/organization-result.dto";
import { OrganizationAPI } from "../../../../../API/organization.api";
import { OrganizationCreateDto } from "../../../../../API/Dto/organizationcn-create.dto";
import ModalEditor from "../../../../../Layouts/ModalEditor/ModalEditor";
import { useEffect, useState } from "react";
import dayjs from "dayjs";

export default function Editor(props: {
  messenger: MessageInstance;
  editing?: OrganizationResultDto;
  onDone: () => void;
}) {
  const [editing, setEditing] = useState<OrganizationResultDto>();
  useEffect(() => {
    if (!props.editing) return;
    setEditing({ ...props.editing!, validUntil: dayjs(props.editing!.validUntil) });
  }, [props.editing]);

  return (
    <ModalEditor
      messenger={props.messenger}
      width={420}
      name={"组织"}
      editing={editing}
      onDone={props.onDone}
      onSubmit={async (d: any) => {
        const formData: OrganizationCreateDto = {
          name: d.name,
          validUntil: d.validUntil,
        };
        await (props.editing
          ? OrganizationAPI.renameById(props.editing.id, formData)
          : OrganizationAPI.create(formData));
      }}
    >
      <Form.Item
        label="名称"
        name="name"
        rules={[{ required: true, message: "请输入组织名称" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="有效期至"
        name="validUntil"
        rules={[{ required: true, message: "请选择有效期" }]}
      >
        <DatePicker />
      </Form.Item>
      <Form.Item className="save-button">
        <Button type="primary" htmlType="submit">
          {props.editing ? "确认修改" : "确认创建"}
        </Button>
      </Form.Item>
    </ModalEditor>
  );
}
