/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { Button, Dropdown, Input, MenuProps, Modal, Table } from "antd";
import { useEffect, useState } from "react";
import { MessageInstance } from "antd/es/message/interface";
import Editor from "./Editor/Editor";
import { OrganizationResultDto } from "../../../../API/Dto/organization-result.dto";
import { OrganizationListResultDto } from "../../../../API/Dto/organization-list-result.dto";
import ActionBar from "../../../../Layouts/LayoutHome/ActionBar/ActionBar";
import moment from "moment";
import { MessengerWrapper } from "../../../../Common/MessengerWrapper";
import { OrganizationAPI } from "../../../../API/organization.api";
import Relation from "./Relation/Relation";
import { SearchOutlined } from "@ant-design/icons";
import Recover from "./Recover/Recover";

export default function Organization(props: { messenger: MessageInstance }) {
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(15);

  const [rows, setRows] = useState<OrganizationListResultDto[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);

  const [editingRow, setEditingRow] = useState<OrganizationResultDto>();
  const [showEditor, setShowEditor] = useState<boolean>(false);
  const [showRecover, setShowRecover] = useState<boolean>(false);
  const [showRelation, setShowRelation] = useState<boolean>(false);

  const [searchCondition, setSearchCondition] = useState<Record<string, string | string[]>>({});

  useEffect(() => {
    refresh();
  }, [page, pageSize, searchCondition]);

  async function search(condition: Record<string, any[]>) {
    const newCondition: Record<string, string | string[]> = {};
    if (condition.name) newCondition.name = condition.name[0];
    if (condition.validUntil) newCondition.validUntil = condition.validUntil.map(v => v.toString());
    setSearchCondition({ ...newCondition });
  }

  async function refresh() {
    setShowEditor(false);
    setShowRelation(false);
    setShowRecover(false);

    MessengerWrapper(props.messenger, async () => {
      if (1 === page) {
        setRowCount(await OrganizationAPI.count(searchCondition));
      }
      setRows(await OrganizationAPI.page(page, pageSize, searchCondition));
    });
  }

  function create() {
    setEditingRow(undefined);
    setShowEditor(true);
  }

  function edit(record: OrganizationResultDto) {
    setEditingRow(record);
    setShowEditor(true);
  }

  function recover(record: OrganizationResultDto) {
    setEditingRow(record);
    setShowRecover(true);
  }

  function relation(record: OrganizationResultDto) {
    setEditingRow(record);
    setShowRelation(true);
  }

  function remove(record: OrganizationResultDto) {
    Modal.confirm({
      title: "确认删除?",
      content: `此操作将不可恢复的删除${record.name}，及其所有幸福大使和用户`,
      onOk: () => {
        MessengerWrapper(props.messenger, async () => {
          await OrganizationAPI.remove(record.id);
          refresh();
        });
      },
    });
  }

  return (
    <div>
      <ActionBar>
        <Button className="add" type="ghost" onClick={() => create()}>
          {"创建公司"}
        </Button>
      </ActionBar>
      <div className="table-title">所有公司</div>
      <Table
        rowKey={"id"}
        bordered={false}
        dataSource={rows}
        onChange={(p, f: Record<string, any>) => p.current === page && search(f)}
        columns={[
          {
            key: "name",
            title: "名称",
            dataIndex: "name",
            filterDropdown: ({ selectedKeys, setSelectedKeys, confirm, clearFilters, close }) => (
              <div className="table-search">
                <Input
                  placeholder="搜索名称"
                  value={selectedKeys[0]}
                  onChange={(e) => setSelectedKeys((e.target.value ? [e.target.value] : []) as any)}
                />
                <Button
                  type="primary"
                  className="search-button"
                  onClick={() => confirm()}
                >
                  搜索
                </Button>
                <Button
                  type="default"
                  className="reset-button"
                  onClick={() => {
                    clearFilters!();
                  }}
                >
                  重置
                </Button>
                <Button
                  type="link"
                  className="close-button"
                  onClick={() => {
                    close();
                  }}
                >
                  关闭
                </Button>
              </div>
            ),
            filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />,
          },
          {
            key: "secretary_count",
            title: "幸福大使",
            dataIndex: ["_count", "servants"],
            width: 160,
          },
          {
            key: "validUntilText",
            title: "合同到期",
            dataIndex: "validUntil",
            render: (value) => moment(new Date(value)).format("YYYY/MM/DD"),
            width: 200,
          },
          {
            key: "validUntil",
            title: "服务状态",
            dataIndex: "validUntil",
            filters: [
              {
                text: '正常',
                value: true,
              },
              {
                text: '已终止',
                value: false,
              },
            ],
            render: (value) => {
              const date = new Date(value);
              return new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime() > Date.now() ? '正常' : '已终止';
            },
            width: 180,
          },
          {
            key: "action",
            title: "操作",
            dataIndex: "id",
            width: 60,
            className: 'table-action-column',
            render: (value, record, index) => {
              const date = new Date(record.validUntil);
              const items: MenuProps['items'] = [
                {
                  key: 'assign',
                  label: "关联幸福大使",
                  onClick: () => relation(record),
                },
                {
                  key: 'edit',
                  label: "编辑",
                  onClick: () => edit(record),
                },
                {
                  key: 'delete',
                  label: "删除",
                  onClick: () => remove(record),
                },
                ...((new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime() <= Date.now()) ? [{
                  key: 'recover',
                  label: "恢复",
                  onClick: () => recover(record),
                }] : []),
              ];

              return <Dropdown menu={{ items }}>
                <a onClick={(e) => e.preventDefault()} className="table-action-more"></a>
              </Dropdown>;
            },
          },
        ]}
        pagination={{
          pageSize,
          total: rowCount,
          pageSizeOptions: [10, 15, 20, 50, 100],
          showSizeChanger: true,
          position: ["bottomRight"],
          onChange: (page, pageSize) => {
            setPage(page);
            setPageSize(pageSize);
          },
        }}
      ></Table>
      {showEditor && (
        <Editor
          messenger={props.messenger}
          editing={editingRow}
          onDone={() => refresh()}
        />
      )}
      {showRelation && editingRow && (
        <Relation
          messenger={props.messenger}
          editing={editingRow}
          onDone={() => refresh()}
        />
      )}
      {showRecover && editingRow && (
        <Recover
          messenger={props.messenger}
          editing={editingRow}
          onDone={() => refresh()}
        />
      )}
    </div>
  );
}
