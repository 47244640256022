/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { MessageInstance } from "antd/es/message/interface";
import LayoutLogin from "../../../../Layouts/LayoutLogin/LayoutLogin";

export default function Done(props: { messenger: MessageInstance }) {
  const navigate = useNavigate();

  async function enterHome() {
    navigate("/home");
  }

  return (
    <LayoutLogin>
      <div className="legend only">更新密码成功</div>
      <div className="button-link-group">
        <div className="button-group">
          <Button className={"submit-button"} onClick={enterHome}>
            进入首页
          </Button>
        </div>
      </div>
    </LayoutLogin>
  );
}
