/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { Button, Form, Input } from "antd";

import { MessageInstance } from "antd/es/message/interface";

import ModalEditor from "../../../../../Layouts/ModalEditor/ModalEditor";
import { SecretaryResultDto } from "../../../../../API/Dto/secretary-result.dto";
import { SecretaryAPI } from "../../../../../API/secretary.api";
import { SecretaryCreateDto } from "../../../../../API/Dto/secretary-create.dto";
import { SecretaryRole } from "../../../../../API/Enum/secretary-role";

export default function Editor(props: {
  messenger: MessageInstance;
  editing?: SecretaryResultDto;
  onDone: () => void;
}) {

  return (
    <ModalEditor
      messenger={props.messenger}
      editing={
        props.editing
          ? {
            ...props.editing,
            organizationIds: props.editing.organizations.map(
              (v) => v.organizationId
            ),
          }
          : undefined
      }
      name={"内容管理员"}
      onValueChange={(k, v) => {
        if (props.editing) {
          Object.assign(props.editing, k);
        }
      }}
      onDone={props.onDone}
      onSubmit={async (d: any) => {
        const formData: SecretaryCreateDto = {
          name: d.name,
          countryCode: "86",
          mobile: d.mobile,
          role: SecretaryRole.CONTENT_MANAGER,
          password: d.password,
          organizationIds: [],
          consultantRole: [],
        };
        await (props.editing
          ? SecretaryAPI.updateById(props.editing.id, formData)
          : SecretaryAPI.create(formData));
      }}
    >
      <Form.Item
        label="名称"
        name="name"
        rules={[{ required: true, message: "请输入名称" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="手机号"
        name="mobile"
        rules={[{ required: true, message: "请输入手机号" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="初始密码"
        name="password"
        rules={[{ required: !props.editing, message: "请输入初始密码" }]}
        hidden={!!props.editing}
      >
        <Input />
      </Form.Item>
      <Form.Item className="save-button">
        <Button type="primary" htmlType="submit">
          {props.editing ? "确认修改" : "确认创建"}
        </Button>
      </Form.Item>
    </ModalEditor>
  );
}
