/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { MessageInstance } from "antd/es/message/interface";
import ActionBar from "../../../../Layouts/LayoutHome/ActionBar/ActionBar";
import { Button, Dropdown, Input, MenuProps, Radio, Table, Tabs } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { ConsultTopicListResultDto } from "../../../../API/Dto/consult-topic-list-result.dto";
import { ConsultAPI } from "../../../../API/consult.api";
import { MessengerWrapper } from "../../../../Common/MessengerWrapper";
import { SearchOutlined } from "@ant-design/icons";
import { SorterResult } from "antd/es/table/interface";
import { ConsultantTag } from "../../../../API/Enum/consultant-tag";
import { AuthAPI } from "../../../../API/Auth.api";
import { Link, useNavigate } from "react-router-dom";

import './Topic.scss';

export default function ConsultTopic(props: { messenger: MessageInstance }) {
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(15);

  const [rows, setRows] = useState<ConsultTopicListResultDto[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);

  const user = AuthAPI.getLocalUser();
  const navigate = useNavigate();

  const [type, setType] = useState(user?.consultantRole[0] || ConsultantTag.LEGAL);
  const [replied, setReplied] = useState(false);
  const [searchCondition, setSearchCondition] = useState<Record<string, string | string[]>>({});

  async function search(condition: Record<string, any[]>, sort: SorterResult<any>) {
    const newCondition: Record<string, string | string[]> = {};
    if (condition.title) newCondition.title = condition.title[0];
    if (sort.field) newCondition[`sort-${sort.field}`] = ({ 'ascend': 'asc', 'descend': 'desc' })[sort.order!];
    setSearchCondition({ ...newCondition });
  }

  async function refresh() {

    MessengerWrapper(props.messenger, async () => {
      if (1 === page) {
        setRowCount(await ConsultAPI.countTopic({ ...searchCondition, consultantTag: type, consultPosts: replied ? user?.id : undefined }));
      }
      setRows(
        await ConsultAPI.pageTopic(page, pageSize, { ...searchCondition, consultantTag: type, consultPosts: replied ? user?.id : undefined })
      );
    });
  }

  useEffect(() => {
    refresh();
  }, [page, pageSize, searchCondition, type, replied]);

  function view(topic: ConsultTopicListResultDto) {
    navigate(`/home/consult/topic-detail?id=${topic.id}`);
  }


  return (
    <div>
      <ActionBar buttons={[]}></ActionBar>
      <Radio.Group className="posted-tab" onChange={(v) => setReplied(v.target.value)} value={replied}>
        <Radio.Button value={false}>提问列表</Radio.Button>
        <Radio.Button value={true}>我的回复</Radio.Button>
      </Radio.Group>
      <Tabs
        defaultActiveKey={type}
        className="topic-tab"
        items={[{
          key: ConsultantTag.LEGAL,
          label: '法律',
        },
        {
          key: ConsultantTag.PSYCHOLOGICAL,
          label: '心理',
        }].filter(v => user?.consultantRole.includes(v.key))}
        onChange={(key) => setType(key)}
      />
      <Table
        id="consultTopic"
        rowKey={"id"}
        dataSource={rows}
        onChange={(_, f: Record<string, any>, s: any) => search(f, s)}
        columns={[
          // {
          //   key: "consultantTag",
          //   title: "类型",
          //   dataIndex: "consultantTag",
          //   width: 100,
          //   filters: [
          //     {
          //       text: "法律",
          //       value: "LEGAL",
          //     },
          //     {
          //       text: "心理",
          //       value: "PSYCHOLOGICAL",
          //     },
          //   ],
          //   render: (v) =>
          //     ({ LEGAL: "法律", PSYCHOLOGICAL: "心理" }[v as string]),
          // },
          {
            key: "title",
            title: "标题",
            dataIndex: "title",
            render: (v, r) => <Link to={`/home/consult/topic-detail?id=${r.id}`}>{v}</Link>,
            filterDropdown: ({ selectedKeys, setSelectedKeys, confirm, clearFilters, close }) => (
              <div className="table-search">
                <Input
                  placeholder="搜索标题"
                  value={selectedKeys[0]}
                  onChange={(e) => setSelectedKeys((e.target.value ? [e.target.value] : []) as any)}
                />
                <Button
                  type="primary"
                  className="search-button"
                  onClick={() => confirm()}
                >
                  搜索
                </Button>
                <Button
                  type="default"
                  className="reset-button"
                  onClick={() => {
                    clearFilters!();
                  }}
                >
                  重置
                </Button>
                <Button
                  type="link"
                  className="close-button"
                  onClick={() => {
                    close();
                  }}
                >
                  关闭
                </Button>
              </div>
            ),
            filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />,
          },
          {
            key: "postCount",
            title: "回复数",
            dataIndex: "postCount",
            width: 100,
          },
          // {
          //   key: "hasPost",
          //   title: "咨询已回复",
          //   dataIndex: "hasPost",
          //   width: 120,
          //   render: (value) => (value ? "是" : "否"),
          // },
          {
            key: "createdAt",
            title: "发布时间",
            dataIndex: "createdAt",
            sorter: true,
            render: (value) =>
              value ? moment(value).format("YYYY/MM/DD") : "未发布",
            width: 200,
          },
          {
            key: "action",
            title: "操作",
            dataIndex: "id",
            width: 60,
            render: (value, record, index) => {
              const items: MenuProps['items'] = [
                {
                  key: 'view',
                  label: "查看",
                  onClick: () => view(record),
                },
              ];

              return <Dropdown menu={{ items }}>
                <a onClick={(e) => e.preventDefault()} className="table-action-more"></a>
              </Dropdown>;

            },
          },
        ]}
        pagination={{
          pageSize,
          total: rowCount,
          pageSizeOptions: [10, 15, 20, 50, 100],
          showSizeChanger: true,
          position: ["bottomRight"],
          onChange: (page, pageSize) => {
            setPage(page);
            setPageSize(pageSize);
          },
        }}
      ></Table>
    </div>
  );
}
