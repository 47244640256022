/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { Button, Form, FormInstance, Input } from "antd";
import { useEffect, useRef, useState } from "react";
import { AuthAPI } from "../../../../API/Auth.api";
import { MessageInstance } from "antd/es/message/interface";

import './Mobile.scss';
import { MessengerWrapper } from "../../../../Common/MessengerWrapper";
import ModalEditor from "../../../../Layouts/ModalEditor/ModalEditor";

export default function Mobile(props: { messenger: MessageInstance; onDone: () => void }) {

  const [localUser, setLocalUser] = useState(AuthAPI.getLocalUser());
  const formRef = useRef<FormInstance>(null)
  const [originCounter, setOriginCounter] = useState(0);
  const [newCounter, setNewCounter] = useState(0);

  useEffect(() => {
    if (0 == originCounter) return;
    setTimeout(() => setOriginCounter(originCounter - 1), 1000)
  }, [originCounter]);

  useEffect(() => {
    if (0 == newCounter) return;
    setTimeout(() => setNewCounter(newCounter - 1), 1000)
  }, [newCounter]);

  useEffect(() => {
    formRef.current?.setFieldsValue(formData);
  }, []);

  const [formData, setFormData] = useState<any>({ originMobile: localUser?.mobile });

  const [phase, setPhase] = useState(0);

  async function perform(data: any) {
    MessengerWrapper(props.messenger, async () => {
      await AuthAPI.changeMobile(data);
      props.messenger.success('手机号已修改');
      const newUser = AuthAPI.getLocalUser();
      setLocalUser(newUser);
      formRef.current?.resetFields();
      formRef.current?.setFieldsValue({ originMobile: newUser?.mobile });
    });
  }

  function sendOriginCaptcha() {
    if (!formData.originMobile?.trim()) {
      props.messenger.error('原手机号无效');
      return;
    }
    MessengerWrapper(props.messenger, async () => {
      await AuthAPI.requestCaptchaByToken(formData.originMobile ?? "");
      setOriginCounter(60);
    })
  }

  function sendNewCaptcha() {
    if (!formData.newMobile?.trim()) {
      props.messenger.error('无效的新手机号');
      return;
    }
    MessengerWrapper(props.messenger, async () => {
      await AuthAPI.requestCaptchaByToken(formData.newMobile ?? "");
      setNewCounter(60);
    })
  }

  function gotoNewMobilePhase() {
    setPhase(1);
  }

  return <ModalEditor
    messenger={props.messenger}
    name={""}
    className="mobile-modal"
    width={410}
    title={0 === phase ? "原手机号验证" : "新手机号验证"}
    formRef={formRef}
    onDone={props.onDone}
    onSubmit={function (d: any): void | Promise<void> {
      perform(d);
    }}
    onValueChange={(_, v) => {
      setFormData(v);
    }}
  >
    <div hidden={0 !== phase}>
      <div className="mobile-hint">* 如原手机号不可用请联系你的客服。</div>
      <Form.Item label="原手机号" name="originMobile" required>
        <Input />
      </Form.Item>
      <Form.Item label="验证码" name="originCaptcha" required>
        <Input placeholder="请输入验证码" suffix={<Button type="text" onClick={sendOriginCaptcha} disabled={0 < originCounter}>{0 != originCounter ? `重新发送(${originCounter})` : '获取验证码'}</Button>} />

      </Form.Item>
      <Form.Item className="save-button">
        <Button type="primary" onClick={gotoNewMobilePhase}>
          {"下一步"}
        </Button>
      </Form.Item>
    </div>
    <div hidden={1 !== phase}>
      <Form.Item label="新手机" name='newMobile' required>
        <Input />
      </Form.Item>
      <Form.Item label="验证码" name="newCaptcha" required>
        <Input placeholder="请输入验证码" suffix={<Button type="text" onClick={sendNewCaptcha} disabled={0 < newCounter}>{0 != newCounter ? `重新发送(${newCounter})` : '发送验证码'}</Button>} />
      </Form.Item>
      <Form.Item className="save-button">
        <Button type="primary" htmlType="submit">
          {"确认修改"}
        </Button>
      </Form.Item>
    </div>
  </ModalEditor >;
}