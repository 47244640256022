/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { MessageInstance } from "antd/es/message/interface";
import LayoutHome from "../../Layouts/LayoutHome/LayoutHome";
import { Navigate, Route, Routes } from "react-router-dom";
import { AuthAPI } from "../../API/Auth.api";
import Organization from "./System/Organization/Organization";
import CSUser from "./System/User/User";
import VideoTag from "./Video/Tag/Tag";
import Video from "./Video/Video/Video";
import Category from "./Article/Category/Category";
import Article from "./Article/Article/Article";
import Notification from "./System/Notification/Notification";
import ConsultTopic from "./Consult/Topic/Topic";
import { SecretaryRole } from "../../API/Enum/secretary-role";
import ConsultProfile from "./Settings/Profile/Profile";
import Version from "./System/Version/Version";
import DateTagFamily from "./Date/Tag-Family/Tag-Family";
import DateTagOption from "./Date/Tag-Option/Tag-Option";
import Butler from "./System/Butler/Butler";
import Consultant from "./Consult/Consultant/Consultant";
import ConsultDetail from "./Consult/Topic/Detail/Detail";
import CustomerService from "./System/CustomerService/CustomerService";
import ButlerUser from "./User/User/User";
import ContentManager from "./System/ContentManager/ContentManager";
import DateComplain from "./Date/Complain/Complain";

export default function Home(props: { messenger: MessageInstance }) {
  const localUser = AuthAPI.getLocalUser();
  return (
    <LayoutHome messenger={props.messenger}>
      <Routes>
        <Route
          path="*"
          element={
            <Navigate
              to={
                localUser ? ({
                  [SecretaryRole.CONSULTANT]: "/home/consult",
                  [SecretaryRole.BUTLER]: "/home/user",
                  [SecretaryRole.CUSTOMER_SERVICE]: "/home/customer",
                  [SecretaryRole.CONTENT_MANAGER]: "/home/news",
                })[localUser.role]
                  : "/login"
              }
              replace
            />
          }
        />
        <Route
          path="/user"
          element={<Navigate to="/home/user/user" replace />}
        />
        <Route
          path="/user/user"
          element={<ButlerUser messenger={props.messenger} />}
        />
        <Route
          path="/customer"
          element={<Navigate to={localUser ? (SecretaryRole.BUTLER === localUser.role ? "/home/customer/user" : "/home/customer/organization") : "/home/customer/organization"} replace />}
        />
        <Route
          path="/customer/organization"
          element={<Organization messenger={props.messenger} />}
        />
        <Route
          path="/customer/butler"
          element={<Butler messenger={props.messenger} />}
        />
        <Route
          path="/customer/customer-service"
          element={<CustomerService messenger={props.messenger} />}
        />
        <Route
          path="/customer/content-manager"
          element={<ContentManager messenger={props.messenger} />}
        />
        <Route
          path="/customer/user"
          element={<CSUser messenger={props.messenger} />}
        />
        <Route
          path="/customer/consultant"
          element={<Consultant messenger={props.messenger} />}
        />
        <Route
          path="/notification"
          element={<Navigate to={"/home/notification/notification"} replace />}
        />
        <Route
          path="/notification/notification"
          element={<Notification messenger={props.messenger} />}
        />
        <Route
          path="/version"
          element={<Navigate to={"/home/version/version"} replace />}
        />
        <Route
          path="/version/version"
          element={<Version messenger={props.messenger} />}
        />
        <Route
          path="/news"
          element={<Navigate to={"/home/news/article"} replace />}
        />
        <Route
          path="/news/category"
          element={<Category messenger={props.messenger} />}
        />
        <Route
          path="/news/article"
          element={<Article messenger={props.messenger} />}
        />
        <Route
          path="/news/tag"
          element={<VideoTag messenger={props.messenger} />}
        />
        <Route
          path="/news/video"
          element={<Video messenger={props.messenger} />}
        />
        <Route
          path="/consult"
          element={<Navigate to={"/home/consult/topic"} replace />}
        />
        <Route
          path="/consult/topic"
          element={<ConsultTopic messenger={props.messenger} />}
        />
        <Route
          path="/consult/topic-detail"
          element={<ConsultDetail messenger={props.messenger} />}
        />
        <Route
          path="/metadata"
          element={<Navigate to={"/home/metadata/date-tag-family"} replace />}
        />
        <Route
          path="/metadata/date-tag-family"
          element={<DateTagFamily messenger={props.messenger} />}
        />
        <Route
          path="/metadata/date-tag-option"
          element={<DateTagOption messenger={props.messenger} />}
        />
        <Route
          path="/metadata/date-complain"
          element={<DateComplain messenger={props.messenger} />}
        />
        <Route
          path="/profile"
          element={<Navigate to={"/home/profile/profile"} replace />}
        />
        <Route
          path="/profile/profile"
          element={<ConsultProfile messenger={props.messenger} />}
        />

      </Routes>
    </LayoutHome>
  );
}
