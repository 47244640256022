/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { decode } from "js-base64";
import { SecretaryResultDto } from "./Dto/secretary-result.dto";
import { HttpAPI } from "./HttpAPI";

export class AuthAPI {
  static prefetchCaptcha(
    mobile: string,
    password: string
  ): Promise<SecretaryResultDto> {
    return HttpAPI.get(`/auth/prefetch/${mobile}/${password}`);
  }

  static trustLogin(): Promise<SecretaryResultDto> {
    return HttpAPI.get(`/auth/trust`);
  }

  static requestCaptcha(mobile: string, password: string): Promise<void> {
    return HttpAPI.get(`/auth/send/${mobile}/${password}`);
  }

  static requestCaptchaByRecover(mobile: string): Promise<void> {
    return HttpAPI.get(`/auth/recover/${mobile}`);
  }

  static requestCaptchaByToken(mobile: string): Promise<void> {
    return HttpAPI.get(`/auth/send/${mobile}`);
  }

  static prepareRecover(mobile: string, captcha: string): Promise<void> {
    return HttpAPI.get(`/auth/recover/${mobile}/${captcha}`);
  }

  static performRecover(password: string): Promise<void> {
    return HttpAPI.patch(`/auth/recover`, { password });
  }

  static resendCaptcha(): Promise<SecretaryResultDto> {
    return HttpAPI.get(`/auth/resend`);
  }

  static verifyCaptcha(
    mobile: string,
    captcha: string
  ): Promise<SecretaryResultDto> {
    return HttpAPI.get(`/auth/verify/${mobile}/${captcha}`);
  }

  static getLocalUser(): SecretaryResultDto | null {
    const tokenParts = localStorage
      .getItem("secretary-access-token")
      ?.split(".");
    try {
      return JSON.parse(
        decode((tokenParts || [])[1].replace("-", "+").replace("_", "/"))
      ).admin;
    } catch {
      return null;
    }
  }

  static async initialPassword(password: string) {
    return HttpAPI.post(`/auth/initial`, {
      password,
    });
  }

  static async update(data: any) {
    return HttpAPI.patch(`/auth`, data);
  }

  static logout() {
    HttpAPI.clearOrganization();
    return HttpAPI.delete(`/auth`);
  }

  static changeMobile(data: any) {
    return HttpAPI.patch(`/auth/mobile`, data);
  }
  static changePassword(originPassword: string, newPassword: string) {
    return HttpAPI.patch(`/auth/password`, {
      originPassword,
      newPassword,
    });
  }
}
