/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { Button, Modal, Table } from "antd";
import { useEffect, useState } from "react";
import Editor from "./Editor/Editor";
import { MessageInstance } from "antd/es/message/interface";
import { CategoryTreeResultDto } from "../../../../API/Dto/category-tree-result.dto";
import { MessengerWrapper } from "../../../../Common/MessengerWrapper";
import ActionBar from "../../../../Layouts/LayoutHome/ActionBar/ActionBar";
import { CategoryAPI } from "../../../../API/category.api";

export default function Category(props: { messenger: MessageInstance }) {
  const [parentCategory, setParentCategory] = useState<CategoryTreeResultDto>();
  const [editingCategory, setEditingCategory] =
    useState<CategoryTreeResultDto>();

  const [categories, setCategories] = useState<CategoryTreeResultDto[]>([]);
  const [showCreate, setShowCreate] = useState<boolean>(false);

  useEffect(() => {
    refresh();
  }, []);

  async function refresh() {
    setParentCategory(undefined);
    setShowCreate(false);
    const gotCategories = await CategoryAPI.getTree();
    setCategories(gotCategories);
  }

  function create(parent?: CategoryTreeResultDto) {
    setEditingCategory(undefined);
    setParentCategory(parent);
    setShowCreate(true);
  }

  function edit(record: CategoryTreeResultDto) {
    setEditingCategory(record);
    setParentCategory(undefined);
    setShowCreate(true);
  }

  function remove(record: CategoryTreeResultDto) {
    Modal.confirm({
      title: "确认删除?",
      content: `此操作将不可恢复的删除 ${record.display}`,
      onOk: () => {
        MessengerWrapper(props.messenger, async () => {
          await CategoryAPI.remove(record.id);
          refresh();
        });
      },
    });
  }

  return (
    <div>
      <ActionBar>
        <Button type="primary" className="add" onClick={() => create()}>
          {"创建新顶级分类"}
        </Button>
      </ActionBar>
      <div className="table-title">所有分类</div>
      <Table
        rowKey={"id"}
        dataSource={categories}
        expandable={{ childrenColumnName: 'none' }}
        columns={[
          { key: "display", title: "名称", dataIndex: "display", width: 300 },
          { key: "description", title: "描述", dataIndex: "description" },
          {
            key: "action",
            title: "操作",
            dataIndex: "id",
            width: 260,
            render: (value, record, index) => (
              <>
                {/* <Button type="link" onClick={() => create(record)}>
                  创建子分类
                </Button> */}
                <Button type="link" onClick={() => edit(record)}>
                  编辑
                </Button>
                <Button type="link" onClick={() => remove(record)}>
                  删除
                </Button>
              </>
            ),
          },
        ]}
        pagination={false}
      ></Table>
      {showCreate && (
        <Editor
          messenger={props.messenger}
          parent={parentCategory}
          editing={editingCategory}
          onDone={() => refresh()}
        />
      )}
    </div>
  );
}
