/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { Button, Dropdown, Input, MenuProps, Modal, Table } from "antd";
import { useEffect, useState } from "react";
import { MessageInstance } from "antd/es/message/interface";
import Editor from "./Editor/Editor";
import ActionBar from "../../../../Layouts/LayoutHome/ActionBar/ActionBar";
import moment from "moment";
import { MessengerWrapper } from "../../../../Common/MessengerWrapper";
import { VideoListResultDto } from "../../../../API/Dto/video-list-result.dto";
import { VideoResultDto } from "../../../../API/Dto/video-result.dto";
import { VideoAPI } from "../../../../API/video.api";
import { SearchOutlined } from "@ant-design/icons";
import { SorterResult } from "antd/es/table/interface";

export default function Video(props: { messenger: MessageInstance }) {
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(15);

  const [rows, setRows] = useState<VideoListResultDto[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);

  const [editingRow, setEditingRow] = useState<VideoResultDto>();
  const [showEditor, setShowEditor] = useState<boolean>(false);

  // const [tags, setTags] = useState<VideoTagListResultDto[]>([]);
  const [searchCondition, setSearchCondition] = useState<Record<string, string | string[]>>({});

  useEffect(() => {
    refresh();
  }, [page, pageSize, searchCondition]);

  async function refresh() {
    setShowEditor(false);

    MessengerWrapper(props.messenger, async () => {
      if (1 === page) {
        setRowCount(await VideoAPI.count(searchCondition));
      }
      setRows(await VideoAPI.page(page, pageSize, searchCondition));
    });
  }

  function create() {
    setEditingRow(undefined);
    setShowEditor(true);
  }

  async function edit(record: VideoListResultDto) {
    setEditingRow(await VideoAPI.getById(record.id));
    setShowEditor(true);
  }

  function remove(record: VideoListResultDto) {
    Modal.confirm({
      title: "确认删除?",
      content: `此操作将不可恢复的删除 ${record.title}`,
      onOk: () => {
        MessengerWrapper(props.messenger, async () => {
          await VideoAPI.removeById(record.id);
          refresh();
        });
      },
    });
  }

  async function publish(record: VideoListResultDto) {
    Modal.confirm({
      title: "确认发布?",
      content: `此操作会导致 ${record.title} 成为公开访问的文章`,
      onOk: () => {
        MessengerWrapper(props.messenger, async () => {
          await VideoAPI.publish(record.id);
          refresh();
        });
      },
    });
  }

  async function unpublish(record: VideoListResultDto) {
    Modal.confirm({
      title: "确认撤回?",
      content: `此操作会导致 ${record.title} 不再可访问`,
      onOk: () => {
        MessengerWrapper(props.messenger, async () => {
          await VideoAPI.unpublish(record.id);
          refresh();
        });
      },
    });
  }

  async function search(condition: Record<string, any[]>, sort: SorterResult<any>) {
    const newCondition: Record<string, string | string[]> = {};
    if (condition.title) newCondition.title = condition.title[0];
    if (condition.tags) newCondition.tags = condition.tags[0];
    if (sort.field) newCondition[`sort-${sort.field}`] = ({ 'ascend': 'asc', 'descend': 'desc' })[sort.order!];
    setSearchCondition({ ...newCondition });
  }

  return (
    <div>
      <ActionBar>
        <Button type="ghost" className="add" onClick={() => create()}>
          {"创建大讲堂"}
        </Button>
      </ActionBar>
      <Table
        rowKey={"id"}
        dataSource={rows}
        onChange={(_, f: Record<string, any>, s: any) => search(f, s)}
        columns={[
          {
            key: "title",
            title: "标题",
            dataIndex: "title",
            filterDropdown: ({ selectedKeys, setSelectedKeys, confirm, clearFilters, close }) => (
              <div className="table-search">
                <Input
                  placeholder="搜索标题"
                  value={selectedKeys[0]}
                  onChange={(e) => setSelectedKeys((e.target.value ? [e.target.value] : []) as any)}
                />
                <Button
                  type="primary"
                  className="search-button"
                  onClick={() => confirm()}
                >
                  搜索
                </Button>
                <Button
                  type="default"
                  className="reset-button"
                  onClick={() => {
                    clearFilters!();
                  }}
                >
                  重置
                </Button>
                <Button
                  type="link"
                  className="close-button"
                  onClick={() => {
                    close();
                  }}
                >
                  关闭
                </Button>
              </div>
            ),
            filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />,
          },
          {
            key: "tags",
            title: "标签",
            dataIndex: "tags",
            render: (value) => value.map((v: any) => v['tag']['display']).join(', '),
            width: 200,
            filterDropdown: ({ selectedKeys, setSelectedKeys, confirm, clearFilters, close }) => (
              <div className="table-search">
                <Input
                  placeholder="搜索标签"
                  value={selectedKeys[0]}
                  onChange={(e) => setSelectedKeys((e.target.value ? [e.target.value] : []) as any)}
                />
                <Button
                  type="primary"
                  className="search-button"
                  onClick={() => confirm()}
                >
                  搜索
                </Button>
                <Button
                  type="default"
                  className="reset-button"
                  onClick={() => {
                    clearFilters!();
                  }}
                >
                  重置
                </Button>
                <Button
                  type="link"
                  className="close-button"
                  onClick={() => {
                    close();
                  }}
                >
                  关闭
                </Button>
              </div>
            ),
            filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />,
          },
          {
            key: "sort",
            title: "优先级",
            dataIndex: "sort",
            width: 100,
          },
          {
            key: "createdAt",
            title: "创建时间",
            dataIndex: "createdAt",
            render: (value) =>
              value ? moment(value).format("YYYY/MM/DD") : "/",
            width: 120,
            sorter: true,

          },
          {
            key: "publishedAt",
            title: "发布时间",
            dataIndex: "publishedAt",
            render: (value) =>
              value ? moment(value).format("YYYY/MM/DD") : "/",
            width: 120,
            sorter: true,
          },
          {
            key: "action",
            title: "操作",
            dataIndex: "id",
            width: 60,
            render: (value, record, index) => {
              const items: MenuProps['items'] = [
                (!record.publishedAt ? {
                  key: 'publish',
                  label: "发布",
                  onClick: () => publish(record),
                } :
                  {
                    key: 'unpublish',
                    label: "撤回",
                    onClick: () => unpublish(record),
                  }),
                {
                  key: 'edit',
                  label: "编辑",
                  onClick: () => edit(record),
                },
                {
                  key: 'remove',
                  label: "删除",
                  onClick: () => remove(record),
                },
              ];

              return <Dropdown menu={{ items }}>
                <a onClick={(e) => e.preventDefault()} className="table-action-more"></a>
              </Dropdown>;

            },
          },
        ]}
        pagination={{
          pageSize,
          total: rowCount,
          pageSizeOptions: [10, 15, 20, 50, 100],
          showSizeChanger: true,
          position: ["bottomRight"],
          onChange: (page, pageSize) => {
            setPage(page);
            setPageSize(pageSize);
          },
        }}
      ></Table>
      {showEditor && (
        <Editor
          messenger={props.messenger}
          editing={editingRow}
          onDone={() => refresh()}
        />
      )}
    </div>
  );
}
