/*
 * Copyright 2021-Present Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd (www.txz.tech). All Rights Reserved.
 * This material, including without limitation any software, is the confidential trade secret and proprietary
 * information of Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd and its licensors.
 * Reproduction, use and/or distribution of this material in any form is strictly prohibited except as set forth
 * in a written license agreement with Shanghai Jiusi Xinyuan Intelligent Technology Co. Ltd.
 * This material may be covered by one or more patents or pending patent applications.
 */

import { VersionCreateDto } from "./Dto/version-create.dto";
import { HttpAPI } from "./HttpAPI";

export class VersionAPI {
  static async page(page: number, size = 20) {
    return HttpAPI.get(`/version/${page}/${size}`);
  }

  static async count() {
    return HttpAPI.get("/version/count");
  }

  static async create(data: VersionCreateDto) {
    return HttpAPI.post(`/version`, data);
  }

  static async publishAndroidById(id: number) {
    return HttpAPI.post(`/version/${id}/publish/android`);
  }

  static async publishIOSById(id: number) {
    return HttpAPI.post(`/version/${id}/publish/ios`);
  }

  static async removeById(id: number) {
    return HttpAPI.delete(`/version/${id}`);
  }
}
